import { Inject, Injectable } from '@angular/core';
import { AuthorizeService } from '@msslib/services/authorize.service';
import { ClubHubDataService } from '@msslib/services/clubhub-data.service';
import { LendingTypeService } from '@msslib/services/lending-type.service';
import { BasketService } from 'apps/clubhub/src/app/services';
import { CriteriaService } from './criteria.service';
import { Router } from '@angular/router';
import { RioService } from './rio.service';
import { ProductsSearchService } from './products-search.service';
import { IgniteCommonDataService } from './ignite-common-data.service';
import { CriteriaV2OutcomeService } from '../pages/criteria-v2/services/criteria-v2-outcome.service';
import { CriteriaNavigateService } from './criteria-navigate.service';
import { CriteriaSwitcherService } from './criteria-switcher.service';
import { OutcomeHistoryResponse } from '../models/outcome-history';
import { LenderAuditType } from 'apps/shared/src/models/lender-audit';
import { IgniteService } from './ignite.service';
import { UntypedFormGroup } from '@angular/forms';
import { LendingTypeCode, SearchType } from 'apps/shared/src/models/affordability-enums';
import { StepName } from '../models/affordability/step';
import { ResultsView } from '../models/affordability/enums/results-view';
import { INode, OutcomeDetails, OutcomeDetailsCard } from 'apps/shared/src/models/outcome';
import { criteriaSteps } from '../models/criteria/criteria-steps';
import { IBasketItem, IContext, IQuestionAnswerViewModel } from 'apps/clubhub/src/app/models';
import { of } from 'rxjs/internal/observable/of';
import { MiProductsFiltersViewModel } from 'apps/shared/src/models';

@Injectable({
  providedIn: 'root',
})
export class IgniteHistoryService {

  public historyCreatedDate: Date;

  public constructor(
    private clubHubDataService: ClubHubDataService,
    private authService: AuthorizeService,
    private lendingTypeService: LendingTypeService,
    @Inject(BasketService) private basketService,
    private criteriaService: CriteriaService,
    private router: Router,
    private rioService: RioService,
    private productsSearchService: ProductsSearchService,
    private igniteCommonDataService: IgniteCommonDataService,
    private igniteService: IgniteService,
    private criteriaV2OutcomeService: CriteriaV2OutcomeService,
    private criteriaNavigateService: CriteriaNavigateService,
    private criteriaSwitcherService: CriteriaSwitcherService,
  ) { }

  public getHistoricOutcome(
    outcomeSearchId: string,
    isInaccuracy: boolean,
    criteriaV2: boolean,
    savedReport: boolean,
    isSsoUser: boolean,
    agencyNumber?: number,
    key?: string) {
    let outcomeUrl =
        `outcomehistory/${outcomeSearchId}?inaccuracy=${isInaccuracy}&criteriaV2=${criteriaV2}&isSsoUser=${isSsoUser}`;
    if (agencyNumber) {
      outcomeUrl += `&agencyNumber=${agencyNumber}`;
    }
    // this token key is needed to a auth the user on the endpoint if they're not logged in
    if (key) {
      outcomeUrl += `&key=${key}`;
    }
    this.authService.historyView = true;
    this.productsSearchService.startLoadOutcomeHistory();
    this.clubHubDataService
      .get<OutcomeHistoryResponse>(outcomeUrl)
      .subscribe((result) => {
        this.processHistoricOutcome(result, isInaccuracy, savedReport, false);
        setTimeout(() => {
          this.productsSearchService.stopLoadOutcomeHistory();
        }, 300);
      });
  }

  public getHistoricAuditOutcome(referenceId: string, auditType: LenderAuditType) {
    this.productsSearchService.startLoadOutcomeHistory();
    this.clubHubDataService
      .get<OutcomeHistoryResponse>(
        `outcomehistory/audit/${referenceId}?auditType=${auditType}`)
      .subscribe((result) => {
        this.processHistoricOutcome(result, false, false, true);
        setTimeout(() => {
          this.productsSearchService.stopLoadOutcomeHistory();
        }, 300);
      });
  }

  public productsFilters: MiProductsFiltersViewModel[] = [];

  private reset() {
    this.productsFilters = [];
  }

  private processHistoricOutcome(
    result: OutcomeHistoryResponse,
    isInaccuracy: boolean,
    savedReport: boolean,
    audit: boolean,
  ) {
    this.reset();
    this.igniteService.resetResults();
    this.productsSearchService.resetProducts();
    this.productsSearchService.customProductCalculations = result.customProductSettingsViewModel;
    this.igniteCommonDataService.source = result.source;
    this.igniteService.addToSearchInUse(result);
    this.lendingTypeService.setLendingTypeByName(result.lendingType);
    this.igniteService.form.markAsUntouched();
    this.igniteService.currentViewSaved = false;
    this.igniteService.affordabilityModelRequest = null;
    this.igniteService.resetOptions();
    this.igniteService.model = this.igniteService.initialModel();
    this.rioService.initializeRioForm();
    this.igniteService.form = new UntypedFormGroup({});
    this.igniteService.options = {};
    this.igniteService.historicalCriteriaVersion = result.criteriaV2Search ? 2 : 1;
    this.criteriaSwitcherService.criteriaV2Enabled = result.criteriaV2Search;
    this.igniteService.savedReport = savedReport;
    this.historyCreatedDate = result.createdDate;

    if (isInaccuracy) {
      this.igniteCommonDataService.isInaccuracyReport = true;
    } else {
      this.igniteCommonDataService.isOutcomeHistory = true;
    }

    if (result.affordability) {
      if (result.affordability.uiModelJson) {
        this.igniteService.model = JSON.parse(result.affordability.uiModelJson);
        this.igniteService.model?.applicants.applicantAges?.forEach((applicant, i) => {
          applicant.applicantNumber = i + 1;
        });
        this.igniteService.model?.incomeAndExpenditure?.applicants?.forEach((applicant, i) => {
          applicant.applicantNumber = i + 1;
        });
        this.igniteService.affordabilityModelRequest = this.igniteService.mappedModel(this.igniteService.model);
        if (this.igniteService.lendingTypeCode === LendingTypeCode.Res) {
          this.rioService.reset(this.igniteService.model?.applicants.numberOfApplicants, this.igniteService.model);
        }
        this.igniteService.form.reset(this.igniteService.model);
        this.igniteCommonDataService.affordabilityModel = this.igniteService.model ?? null;
      }

      this.igniteCommonDataService.results = {
        pollJobID: null,
        quotes: result.affordability.affordabilityResults,
        affordabilitySearchId: result.affordability.affordabilityResults[0]?.affordabilitySearchId ?? null,
        errorMessage: null,
        completed: true,
        lenderCount: result.affordability.affordabilityResults.length,
      };

      this.igniteService.lenders = result.affordability.affordabilityResults.map(r => (
        {
          lenderName: r.lenderName,
          lenderLogoUrl: r.lenderLogoUrl ?? '',
        }
      ));

      this.igniteService.miSaveOutcomeModel = result.affordability.saveOutcomeModel;
    }

    if (result.criteria) {
      this.setCriteria(result.criteria, null, true);
      this.igniteService.additionalOutcomes = result.criteria.details;
    }

    if (result.intuitiveOutcomes) {
      this.setCriteria(result.intuitiveOutcomes, 'Intuitive Criteria', false);
      this.igniteService.intuitiveOutcomes = result.intuitiveOutcomes.details;
    }

    if (result.property) {
      this.setCriteria(result.property, 'Added Automatically', false);
      this.igniteService.propertyOutcomes = result.property.details;
      this.igniteService.autoAddedPropertyOutcomeIds = result.property.autoAddedOutcomeIds ?? [];
    }

    if (result.products) {
      this.productsFilters = result.products.miProductsFilters;
      this.productsSearchService.processHistoricData(result.products);
      if (audit) {
        this.loadHistoricProducts();
      }
      this.igniteService.matchedLenderProducts = this.productsSearchService.matchedProducts ?? [];
    }

    if (result.source === SearchType.Affordability) {
      this.igniteService.currentStepName = StepName.Results;
      this.igniteService.resultsView = isInaccuracy ? ResultsView.Input : ResultsView.Details;
    }

    if (result.source === SearchType.Criteria || result.source === SearchType.Property) {
      this.criteriaService.step = 'result';
      this.igniteService.currentStepName = StepName.Results;
      this.igniteService.resultsView = ResultsView.Summary;
    }

    if (result.source === SearchType.Product) {
      this.igniteService.currentStepName = StepName.Results;
      this.igniteService.resultsView = ResultsView.MatchedProducts;
    }

    this.igniteService
      .splitOutPropertyCriteria(result.intuitiveOutcomes?.details ?? null, result.criteria?.details ?? []);

    const lendingType = this.lendingTypeService.lendingType?.code ?? '';

    // navigate to place tool where the search was save - property searches will land on the criteria page
    // as they share the same results page
    if ((!!result.property || !!result.criteria) && !result.affordability && !result.products) {
      this.criteriaNavigateService.navigate('issues',
        { queryParams: { historicResult: true, audit } }, result.criteriaV2Search);
    } else if (!!result.products && !result.affordability) {
      this.router.navigateByUrl(`ignite/products/form/${lendingType}?historicResult=true&audit=${audit}`);
    } else {
      this.router.navigateByUrl(`ignite/affordability/form/${lendingType}?historicResult=true&audit=${audit}`);
    }

    this.igniteService.igniteResponseLoading$.next(false);
  }

  private setCriteria(criteria: OutcomeDetails, endOfLabel: string | null, addToBasket: boolean) {
    if (this.igniteService.criteriaV2Enabled) {
      this.criteriaV2OutcomeService.outcome = criteria;
      this.criteriaV2OutcomeService.setBasketItems(this.criteriaV2OutcomeService.outcome.contexts);
      this.criteriaV2OutcomeService.step = criteriaSteps.criteriaResult;
    } else {
      const endOfLabelText = endOfLabel ? ` - ${endOfLabel}` : '';
      const cards: OutcomeDetailsCard[] = [];
      criteria.contexts.forEach(card => {
        cards.push(OutcomeDetailsCard.card(card, endOfLabelText));

        if (addToBasket) {
          if (!this.basketService.basketItems.find((x: IBasketItem) => x.issue.id === card.issueId)) {
            const issue = {
              id: card.issueId,
              text: card.issue,
            } as INode;
            this.basketService.addBasketItem(issue);
          }
          // find added issue and add questions/answers to it
          const issueMatch = this.basketService.basketItems.find((x: IBasketItem) => x.issue.id === card.issueId);
          if (!issueMatch?.contexts.find((x: IContext) => x.context.id === card.contextId)) {
            const questionsAndAnswers = card.questionsAndAnswers.map(x => ({
              question: { id: x.questionId, text: x.question },
              answer: { id: x.answerId, text: x.answer },
            })) as IQuestionAnswerViewModel[];

            const currentContext = {
              id: card.contextId,
              text: card.context,
            } as INode;

            this.basketService.addQuestionAndAnswerMulti(
              card.issueId,
              currentContext,
              questionsAndAnswers,
            );
          }
        }
      });
      if (addToBasket) {
        this.criteriaService.outcomeDetails.details = criteria.details;
      }
      this.criteriaService.outcomeDetails.contexts =
        [...cards, ...this.criteriaService.outcomeDetails?.contexts ?? []];
    }
    this.igniteService.joinCriteriaResults(criteria.details);
  }

  public loadHistoricProducts() {
    if (!this.productsSearchService.productsSearchId) {
      return of();
    }
    this.productsSearchService.productsLoading.next(true);

    return this.productsSearchService.getPreviousOutcome(this.productsSearchService.productsSearchId, false).subscribe(
      result => {
        this.productsFilters = result.miProductsFilters;

        this.productsSearchService.processHistoricProducts(result);
        if (this.igniteCommonDataService.affordabilitySearchId) {
          this.igniteService.matchedLenderProducts = this.productsSearchService.matchedProducts ?? [];
          if (this.igniteCommonDataService.results) {
            this.productsSearchService.matchedProductsFlat =
              this.productsSearchService.getLendersProductsFlat(this.igniteCommonDataService.results);
          }
        }
      });
  }
}
