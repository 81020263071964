import { MethodOfRepayment, MortgageType, Product, RepaymentMethod } from './';
import { GridStateModel } from '@msslib/models/simple-grid';
import { BtlType, ProductType, PropertyType, PurchaserType, RemortgageType, UkLocation } from './affordability-enums';
import { DocumentPreferences } from '@msslib/models/document-preferences.model';
import { PropertyDetails } from 'apps/clubhub/src/app/ignite/models/products/property-details';
import { IgniteResponse } from 'apps/clubhub/src/app/ignite/models';
import { AffordabilityRequest, BtlAffordabilityRequest }
  from 'apps/shared/src/models';
import { ProductFeeType } from '@msslib/constants';

export enum LendingDocumentInformationType {
  Esis = 1,
  Illustration = 2,
}

export enum GeneratedLendingDocumentType {
  Esis = 1,
  Eor = 2,
  Illustration = 3,
}

export enum WhenPayable {
  OnApplication = 1,
  OnCompletion = 2,
  OnOffer = 3,
  OnMaturity = 4,
  UpFront = 5,
}

export enum WhenRefundable {
  NotRefundable = 1,
  PriorToOffer = 2,
  PriorToValuation = 3,
  PriorToCompletion = 4,
}

export enum CriteriaType {
  Manual,
  Property,
  Intuitive,
}

export interface OutcomeViewModel {
  answerId: number;
  outcomeType: CriteriaType;
}

export interface HistoricalEsisEorDocument {
  documentId: string;
  createdDateUtc: string;
  daysRemaining: number;
  generatedTypes: GeneratedLendingDocumentType[];

  personId: string;
  clientForename: string;
  clientSurname: string;
  client2Forename: string | null;
  client2Surname: string | null;
  clientPostCode: string;
  lendingType: string;
}

export interface CostsToBePaid {
  type: ProductFeeType;
  whenPayable: WhenPayable;
  addToLoan: boolean;
  amountRefundable: number;
  whenRefundable: WhenRefundable;
}
export interface CostsToBePaidDetailed {
  type: ProductFeeType;
  amount: number;
  whenPayable: WhenPayable;
  addToLoan: boolean;
  amountRefundable: number;
  whenRefundable: WhenRefundable;
  payableTo: string;
}

export interface FlexibleFeatures {
  type: string;
  text: string;
}

export interface IncentivesAdditionalFeatures {
  type: string;
  text: string;
}

export interface EarlyRepaymentCharges {
  charge: string;
  amount: string;
}

export interface ESISInformation {
  introduction: {
    text: string;
  };
  lenderDetails: {
    lenderName: string;
    buildingName: string;
    city: string;
    state: string;
    country: string;
    postcode: string;
    telephone: string;
  };
  mainFeaturesOfLoan: {
    text: string;
  };
  costsToBePaid: CostsToBePaid[];
  interestRatesOtherCosts: {
    text: string;
    costsNotKnownToLender: string;
  };
  frequencyNumberPayments: {
    text: string;
  };
  amountEachInstalment: {
    text: string;
  };
  externalReferenceRate: number;
  additionalObligations: {
    text: string;
  };
  flexibleFeatures: FlexibleFeatures[];
  incentivesAdditionalFeatures: IncentivesAdditionalFeatures[];
  otherRightsOfBorrower: {
    reflectionPeriod: string;
    otherRights: string;
  };
  earlyRepaymentCharges: EarlyRepaymentCharges[];
  earlyRepaymentChargeTerms: {
    text: string;
  };
  nonCompliance: {
    text: string;
  };
  nonComplianceCi: {
    text: string;
  };
  nonComplianceIo: {
    text: string;
  };
  additionalInformation: {
    text: string;
  };
  linkedSavings: {
    text: string;
  };
  springBoard: {
    text: string;
  };
  sectionsEnabled: {
    lenderDetails: boolean;
    costsToBePaid: boolean;
    externalReferenceRate: boolean;
    additionalObligations: boolean;
    flexibleFeatures: boolean;
    incentivesAdditionalFeatures: boolean;
    otherRightsOfBorrower: boolean;
    earlyRepaymentCharges: boolean;
    earlyRepaymentChargeTerms: boolean;
    nonCompliance: boolean;
    additionalInformation: boolean;
  };
}

export interface EsisEorGenerationRequest {
  originalRequestModel: unknown;
  details: {
    clientDetails: {
      title: string;
      forename: string;
      surname: string;
      forename2: string | null;
      surname2: string | null;
      postcode: string;
      referenceNumber: string;
    };
    loanDetails: {
      mortgageType: MortgageType;
      productTypeExtended: ProductType;
      purchaserType: PurchaserType;
      propertyValue: number;
      loanAmount: number;
      ioLoanAmount: number;
      ltv: number;
      termYears: number;
      termMonths: number;
      repaymentMethod: RepaymentMethod;
    };
    isAdvised: boolean;
    complaintDetails: {
      contactPerson: string;
      addressLine1: string;
      addressLine2: string;
      town: string;
      county: string;
      postcode: string;
      telephone: string;
      email: string;
      maxComplaintHandlingTime: number;
    };
  };
  fees: {
    fees: CostsToBePaidDetailed[];
    feeWaiver: string;
    procuration: {
      lenderIdx: number | null;
      rateId: number | null;
      procFeePaymentAmount: number;
      payableTo: string;
    };
  };
  eor: {
    resultsGrid: {
      rowCount: string;
      title: string;
      description: string;
    };
    recommendationReason: string;
    additionalInformation: string;
  };
  selectedProduct: Product;
  selectedProductRank: number;
  gridState: GridStateModel;
  selectedProductFilters: Record<'mortgageType' | 'initialPeriod' | 'productPreferences', string[]>;
  igniteLenders: string[];
  outcomes: OutcomeViewModel[];
  affordabilityRequest: AffordabilityRequest | BtlAffordabilityRequest;
  affordabilityResponse: IgniteResponse[];
}

export interface EsisEorGenerationResponse {
  documentsId: string;
  typesGenerated: GeneratedLendingDocumentType[];
}

export interface ProcFeeRateViewModel {
  rateId: number;
  productName: string;
  grossPaymentPercent: number;
  grossPaymentAdditional: number;
  grossPaymentMin: number | null;
  grossPaymentMax: number | null;
}

export interface ProcFeeClubViewModel {
  clubName: string;
  rates: ProcFeeRateViewModel[];
}

export interface ProcFeeLenderViewModel {
  lenderName: string;
  clubRates: ProcFeeClubViewModel[];
}

export interface EsisSearchDetailsModel {
  lendingTypeId: number | null;
  lendingTypeCode: string | null;
  mortgageType: MortgageType;
  remortgageType: RemortgageType;
  productTypeExtended: ProductType;
  btlType?: BtlType;
  newBuild?: boolean;
  helpToBuy?: boolean;
  limitedCompanyPurchase?: boolean;
  portfolioLandlord?: boolean;
  purchaserType: PurchaserType;
  propertyValue: number;
  loanAmount: number | null;
  interestOnlyAmount: number;
  outstandingBalanceCurrentMortgage: number;
  mortgageTerm: { years: number | undefined; months: number | undefined };
  repaymentMethod: { value: RepaymentMethod | undefined };
  location: UkLocation | null | undefined;
  propertyType: PropertyType | null;
  propertyDetails?: PropertyDetails | undefined;
  regulated?: boolean;
  limitedCompany?: boolean;
  methodOfRepayment?: { value: MethodOfRepayment | undefined };
  currentMortgageLender?: string;
  productTransferInitialDate?: Date | string;
}

export interface EsisEorGenFormDetails {
  fees: CostsToBePaid[];
  procFeePaymentRates: ProcFeeLenderViewModel[];
  brokerFirmName: string;
  userEsisInformation: DocumentPreferences;
  requiresMoreDetails: boolean;
}

export interface LendingDocumentInformationFormType {
  lendingTypeId: number;
  documentType: LendingDocumentInformationType;
}

export interface IgnitePlusCallbackDocument {
  filename: string;
  documentType: string;
  url: string;
}

export interface ICustomFee {
  value: number;
  name: string;
}

export interface EsisPreviewFormModel {
  mortgageType: MortgageType;
  propertyValue: number;
  loanAmount: number;
  ioLoanAmount: number | null;
  repaymentMethod: RepaymentMethod;
  mortgageTermYears: number;
  mortgageTermMonths: number;
}
