/* eslint-disable no-use-before-define */
import { IDateInput } from '@msslib/components/formly/models/date-input';
import {
  ApplicantCountry,
  BenefitIncomeType,
  BtlType,
  ContractType,
  EmploymentStatus,
  ExpenditureForSubjectPropertyType,
  Frequency,
  HolidayLetFrequency,
  IUiCriteria,
  MethodOfRepayment,
  MortgageType,
  OtherIncomeType,
  OutcomeResponseContextResult,
  ProductLength,
  ProductType,
  PropertyType,
  PropertyUse,
  PurchaserType,
  RemortgageReason,
  RemortgageType,
  RepaymentMethod,
  RepaymentVehicle,
  TaxStatus,
  TaxStatusBtl,
  UkLocation,
  WorkRelatedIncomeType,
  WorkRelatedIncomeTypeBtl,
} from 'apps/shared/src/models';

export interface IPropertyAndLoan {
  location: UkLocation | null;
  propertyType: PropertyType | null;
  newBuild: boolean;
  methodOfRepayment?: MethodOfRepayment;
  mortgageType: MortgageType;
  productTypeExtended: ProductType;
  purchaserType: PurchaserType;
  remortgageType: RemortgageType;
  capitalRaising: ICapitalRaising;
  rentalAmountReceivedExpected: number;
  firstTimeBuyer?: boolean;
  firstTimeLandlord?: boolean;
  ownerOccupier?: boolean;
  capitalRaisingCriteria?: IUiCriteria;
  amountTransferredFromOtherLender?: number;
  remortgageReason?: RemortgageReason;
  propertyValue: number;
  loanAmount: number;
  helpToBuy?: boolean;
  equityLoanStartDate: IDateInput;
  equityLoanValue: number | null;
  equityValue: number;
  repaymentMethod: IRepaymentMethod;
  repaymentMethodInterestOnlyCriteria?: IUiCriteria;
  repaymentMethodInterestOnlyPartAndPartCriteria?: IUiCriteria;
  repaymentVehicle?: RepaymentVehicle;
  interestOnlyAmount?: number;
  mortgageTerm: IUiYearMonth;
  productLength: ProductLength;
  btlType: BtlType;
  holidayLetIncome: IHolidayLetIncome;
  numberOfBedrooms?: number;
  propertySelfContainedFlats? : boolean;
  limitedCompanySpecialPurposeVehicle? : boolean;
  limitedCompanyPurchase: boolean;
  portfolioLandlord: boolean;
  totalPortfolioMortgageBalance?: number;
  totalPortfolioValuation?: number;
  totalGrossMonthlyRent?: number;
  numberOfProperties?: number;
  lettingAgentUsed?: boolean;
  monthlyLettingAgentFee?: number;
  otherExpenditure?: IUiExpenditureForSubjectProperty[];
  mortgageLender?: { value: string };
  currentMortgageLender?: string;
  productTransferInitialDate?: IDateInput;
  numberOfBlocks?: number;
}

export interface IOtherProperties {
  numberOfOtherBTLMortgages: number;
  propertiesBtl: IPropertyBtl[];
  numberOfOtherMortgages: number;
  properties: IUiOtherMortgage[];
}

export interface IHolidayLetIncome {
  holidayLetIncomeLow: number;
  holidayLetIncomeMedium: number;
  holidayLetIncomeHigh: number;
  holidayLetIncomeFrequency: HolidayLetFrequency;
}

export class IPropertyBtl {
  public propertyNumber: number; // UI Only
  public totalBTLMortgageBalanceOutstanding: number;
  public monthlyBTLMortgageRepayment: number;
  public repaymentMethod: RepaymentMethod;
  public repaymentVehicle?: RepaymentVehicle;
  public equityRemainsInProperty?: number;
  public amountInterestOnly?: number;
  public repaymentAmount?: number;
  public interestRate: number;
  public mortgageTerm: IUiYearMonth;
  public rentalAmountReceived: number;
  public static initialPropertyBtl() {
    return {
      rentalAmountReceived: 0,
      totalBTLMortgageBalanceOutstanding: 0,
      monthlyBTLMortgageRepayment: 0,
      interestRate: 0,
      mortgageTerm: {
        months: 0,
        years: null,
      },
    } as unknown as IPropertyBtl;
  }
}

export interface ICapitalRaising {
  value?: boolean;
  showCriteria: boolean;
}

export interface IRepaymentMethod {
  value: RepaymentMethod;
  showInterestOnlyCriteria: boolean;
  showInterestOnlyPartAndPartCriteria: boolean;
}

export interface IApplicants {
  numberOfApplicants: number;
  numberOfIncomesNeeded?: number;
  financialDependants: boolean; // UI Only
  dependantsAged18AndOver: number;
  dependantsAged12To17: number;
  dependantsAged6To11: number;
  dependantsAged0To5: number;
  applicantAges: IUiApplicantAge[] | undefined;
  topSlicing: boolean | null;
}

export interface IIncomeAndExpenditure {
  applicants: IUiApplicant[] | undefined;
  active: boolean;
}

export class IUiAffordabilityModel {
  public propertyAndLoan: IPropertyAndLoan;
  public applicants: IApplicants;
  public incomeAndExpenditure: IIncomeAndExpenditure;
  public otherMortgages?: number;
  public mortgages: IUiOtherMortgage[];
  public otherProperties: IOtherProperties;
  public rioApplicants?: IRio[];
  public static initialModel(initialApplicantAge: IUiApplicantAge, initialApplicant: IUiApplicant) {
    return {
      propertyAndLoan: {
        location: null,
        productTypeExtended: ProductType.Standard,
        propertyType: PropertyType.House,
        newBuild: false,
        repaymentMethod: {
          value: null,
        },
        mortgageLender: {
          value: null,
        },
        currentMortgageLender: null,
        productTransferInitialDate: null,
        mortgageTerm: {
          years: null,
          months: 0,
        },
        productLength: ProductLength.TwoYears,
        otherExpenditure: [],
        equityValue: 0,
        ownerOccupier: null,
      },
      applicants: {
        numberOfApplicants: 1,
        applicantAges: [{ ...initialApplicantAge, applicantNumber: 1 }],
      },
      incomeAndExpenditure: {
        applicants: [{ ...initialApplicant, applicantNumber: 1 }],
      },
      otherProperties: {
        numberOfOtherBTLMortgages: null,
        numberOfOtherMortgages: null,
      },
    } as unknown as IUiAffordabilityModel;
  }
}

export class IUiApplicantAge {
  public applicantNumber: number; // UI Only
  public dateOfBirth: IDateInput;
  public plannedRetirementAge: number;
  public countryResident: ApplicantCountry | null;
  public grossAnnualIncome: number | null;
  public grossAnnualIncomeUkLandProperty: number | null;
  public taxStatus: TaxStatus | null;
  public taxStatusBtl: TaxStatusBtl | null;
  public static initialApplicantAge() {
    return {
      countryResident: null,
      taxStatusBtl: null,
    } as IUiApplicantAge;
  }
}

export class IUiApplicant {
  public applicantNumber: number; // UI Only
  public primaryIncome: IUiMainIncome;
  public secondJob: boolean;
  public secondaryIncome?: IUiMainIncome;
  public additionalIncome: boolean; // UI only
  public ['1Amounts']: number[];
  public ['2Amounts']: number[];
  public ['3Amounts']: number[];
  public [WorkRelatedIncomeType.Bonus]: number; // UI only
  public [WorkRelatedIncomeType.Commission]: number; // UI only
  public [WorkRelatedIncomeType.Overtime]: number; // UI only
  public workAdditionalIncome?: IUiWorkRelatedIncome[];
  public benefitAdditionalIncome?: IUiBenefitIncome[];
  public otherAdditionalIncome?: IUiOtherIncome[];
  public workAdditionalBtlIncome?: IUiWorkRelatedIncomeBtl[];
  public pensionIncome: {
    amount: number | string | undefined;
  };
  public creditCardBalance: number | undefined;
  public willRepayCreditCard: boolean;
  public creditCardBalanceToRepay: number | undefined;
  public totalMonthlyCreditPayments: number | undefined;
  public studentLoanContributions: number | undefined;
  public pensionContributions: number | undefined;
  public householdExpenses: number | undefined;
  public gasAndElectricity: number | undefined;
  public water: number | undefined;
  public tvAndInternet: number | undefined;
  public mobileAndLandlines: number | undefined;
  public councilTax: number | undefined;
  public buildingsAndContentsInsurance: number | undefined;
  public groundRentAndServiceCharge: number | undefined;
  public lifeAssuranceAndPrivateHealthPremiums: number | undefined;
  public travel: number | undefined;
  public childcareAndSchoolCollegeUniversityFees: number | undefined;
  public childMaintenance: number | undefined;
  public carUpkeep: number | undefined;
  public personalEssentials: number | undefined;
  public recreational: number | undefined;
  public other: number | undefined;
  public householdExpenditureSameForAllApplicants: boolean; // UI only
  public otherMortgages?: IUiOtherMortgage[];
  public monthlyRentForResidentialProperty?: number;
  public groundRentServiceCharge?: number;
  public otherCommittedExpenditure?: number;
  public repaymentVehicle?: number;

  public static initialApplicant() {
    return {
      primaryIncome: {
        employmentStatus: null,
        timeInCurrentJobMonths: { years: 0, months: 0 },
        timeRemainingOnContractMonths: { years: 0, months: 0 },
        grossAnnualIncome: '0',
        shareholding: null,
        treatedAsEmployee: null,
        previousAnnualIncome: null,
      },
      additionalIncome: false,
      pensionIncome: {
        amount: '0',
      },
      workAdditionalIncome: [],
      benefitAdditionalIncome: [],
      otherAdditionalIncome: [],
      workAdditionalBtlIncome: [],
    } as unknown as IUiApplicant;
  }
}

export class IUiOtherMortgage {
  public propertyNumber: number;
  public propertyUse?: PropertyUse;
  public propertyValue: number;
  public totalMortgageBalance: number;
  public repaymentMethod?: RepaymentMethod;
  public repaymentVehicle?: RepaymentVehicle;
  public repaymentBalance: number;
  public interestOnlyBalance: number;
  public remainingTermOfLoan?: IUiYearMonth;
  public monthlyMortgagePayments: number;
  public monthlyUtilities: number;
  public monthlyCouncilTax: number;
  public interestRate: number;
  public tenancyInPlace: boolean;
  public grossMonthlyRental: number;
  public equityRemains?: number;
  public static initialPropertyResi() {
    return {
      propertyValue: 0,
      totalMortgageBalance: 0,
      repaymentBalance: 0,
      interestOnlyBalance: 0,
      remainingTermOfLoan: {
        months: 0,
        years: null,
      },
      monthlyMortgagePayments: 0,
      monthlyUtilities: 0,
      monthlyCouncilTax: 0,
      interestRate: 0,
      grossMonthlyRental: 0,
      equityRemains: 0,
    } as unknown as IUiOtherMortgage;
  }
}

export interface IUiMainIncome {
  employmentStatus: EmploymentStatus | null;
  shareholding: number | null;
  contractType?: ContractType;
  treatedAsEmployee: boolean | null;
  timeInCurrentJobMonths?: IUiYearMonth;
  timeRemainingOnContractMonths?: IUiYearMonth;
  grossAnnualIncome: number | string;
  netProfitForLatestPeriod?: number;
  netProfitForPreviousPeriod?: number;
  netProfitForThirdPeriod?: number;
  grossSalaryForLatestPeriod?: number;
  grossSalaryForPreviousPeriod?: number;
  grossSalaryForThirdPeriod?: number;
  grossDividendsForLatestPeriod?: number;
  grossDividendsForPreviousPeriod?: number;
  grossDividendsForThirdPeriod?: number;
  netProfitBeforeDividendsForLatestPeriod?: number;
  netProfitBeforeDividendsForPreviousPeriod?: number;
  netProfitBeforeDividendsForThirdPeriod?: number;
  previousAnnualIncome: number | null;
}

export interface IUiInitialTerm {
  value: number;
  key: string;
  isChecked: boolean;
  label: string;
}

export interface IUiYearMonth {
  years: number;
  months: number;
}

export interface IUiWorkRelatedIncomeOption {
  category: WorkRelatedIncomeType;
  amount: number;
  frequency: Frequency;
  showCriteria: boolean;
}

export interface IUiWorkRelatedIncomeBtlOption {
  category: WorkRelatedIncomeTypeBtl;
  amount: number;
  frequency: Frequency;
  showCriteria: boolean;
}

export interface IUiBenefitIncomeOption {
  category: BenefitIncomeType;
  amount: number;
  frequency: Frequency;
  showCriteria: boolean;
}

export interface IUiOtherIncomeOption {
  category: OtherIncomeType;
  amount: number;
  frequency: Frequency;
  showCriteria: boolean;
}

export interface IUiBaseIncome {
  category: UiBaseIncomeEnumType;
  amount: number | string;
  frequency: Frequency;
  showCriteria: boolean;
}

export interface IUiExpenditureForSubjectPropertyOption {
  category: ExpenditureForSubjectPropertyType;
  amount: number;
  frequency: Frequency;
  showCriteria: boolean;
}

export type UiBaseIncomeEnumType =
  WorkRelatedIncomeType |
  BenefitIncomeType |
  OtherIncomeType |
  WorkRelatedIncomeTypeBtl |
  ExpenditureForSubjectPropertyType;

export interface IUiWorkRelatedIncome {
  option: IUiWorkRelatedIncomeOption;
  criteria: IUiCriteria;
}

export interface IUiBenefitIncome {
  option: IUiBenefitIncomeOption;
  criteria: IUiCriteria;
}
export interface IUiWorkRelatedIncomeBtl {
  option: IUiWorkRelatedIncomeBtlOption;
  criteria: IUiCriteria;
}

export interface IUiOtherIncome {
  option: IUiOtherIncomeOption;
  criteria: IUiCriteria;
}

export interface IUiExpenditureForSubjectProperty {
  option: IUiExpenditureForSubjectPropertyOption;
  criteria: IUiCriteria;
}



export type UiAdditionalIncome = IUiWorkRelatedIncome | IUiBenefitIncome | IUiOtherIncome;

export interface IAdditionalIncomeContext {
  category: WorkRelatedIncomeTypeBtl;
  context: string;
}

export interface IWorkRelatedIncomeContext {
  category: WorkRelatedIncomeType;
  context: string;
}

export interface IBenefitIncomeContext {
  category: BenefitIncomeType;
  context: string;
}

export interface IOtherIncomeContext {
  category: OtherIncomeType;
  context: string;
}

export interface IBaseIncomeContext {
  category:
  WorkRelatedIncomeType |
  BenefitIncomeType |
  OtherIncomeType |
  WorkRelatedIncomeTypeBtl |
  ExpenditureForSubjectPropertyType;
  context: string;
}

export interface IApplicantOutcomes {
  applicantNumber: number;
  outcomes: (OutcomeResponseContextResult | undefined)[];
}

export interface IApplicantOutcomeIds {
  applicantNumber: number;
  outcomeIds: number[];
}

export interface IExpenditureForSubjectPropertyContext {
  category: ExpenditureForSubjectPropertyType;
  context: string;
}

export interface IRio {
  currentPensionIncome: ICurrentPensionIncome;
  futurePensionIncome: IFuturePensionIncome;
  investmentIncome: IInvestmentIncome;
  existingLoans: IExistingLoans;
}

export interface ICurrentPensionIncome {
  annualNonEscalatingAnnuity: IPensionIncomeInputs[];
  annualEscalatingAnnuity: IPensionIncomeInputs[];
  drawdownPot: IPensionIncomeInputs[];
  definedBenefitFinalSalaryPension: IPensionIncomeInputs[];
  annualStatePension: number;
  warWidowsPension: number;
  warDisablementPension: number;
}

export interface IFuturePensionIncome {
  forecastDefinedBenefitFinalSalaryPension: IPensionIncomeInputs[];
  definedContribution: IPensionIncomeInputs[];
  personalPension: IPensionIncomeInputs[];
  expectedAnnualStatePension: number;
}

export interface IPensionIncomeInputs {
  value: number;
  percentage: number;
}

export interface IInvestmentIncome {
  guaranteedInvestmentIncome: number;
  nonGuaranteedInvestmentIncome: number;
}

export interface IExistingLoans {
  monthlySecuredLoans: number;
  monthlyUnsecuredLoans: number;
}

export interface MaxAffordabilityLoanMessage {
  shortMsg: string;
  hoverMsg: string;
}

export const workRelatedIncomeCriteria: IWorkRelatedIncomeContext[] = [
  { category: WorkRelatedIncomeType.Bonus, context: 'Bonus' },
  { category: WorkRelatedIncomeType.Overtime, context: 'Overtime' },
  { category: WorkRelatedIncomeType.Commission, context: 'Commission' },
  { category: WorkRelatedIncomeType.BankWork, context: 'Bank Work' },
  { category: WorkRelatedIncomeType.Bursary, context: 'Bursary' },
  { category: WorkRelatedIncomeType.CarAllowance, context: 'Car Allowance' },
  { category: WorkRelatedIncomeType.LargeTownAllowance, context: 'Large Town Allowance' },
  { category: WorkRelatedIncomeType.LondonWeighting, context: 'London Weighting' },
  { category: WorkRelatedIncomeType.ShiftAllowance, context: 'Shift Allowance' },
];

export const workRelatedIncomeBtlCriteria: IAdditionalIncomeContext[] = [
  { category: WorkRelatedIncomeTypeBtl.CarAllowance, context: 'Car Allowance' },
  { category: WorkRelatedIncomeTypeBtl.ChildBenefit, context: 'Child Benefit' },
  { category: WorkRelatedIncomeTypeBtl.ChildTaxCredits, context: 'Child Tax Credits' },
  { category: WorkRelatedIncomeTypeBtl.ShiftAllowance, context: 'Shift Allowance' },
  { category: WorkRelatedIncomeTypeBtl.UniversalCredit, context: 'Universal Credit' },
  { category: WorkRelatedIncomeTypeBtl.Maintenance, context: 'Maintenance' },
  {
    category: WorkRelatedIncomeTypeBtl.DlaOrPip,
    context: 'Disability Living Allowance/Personal Independence Payments (DLA/PIP)',
  },
  { category: WorkRelatedIncomeTypeBtl.CarersAllowance, context: 'Carers Allowance' },
  { category: WorkRelatedIncomeTypeBtl.WorkingTaxCredits, context: 'Working Tax Credits' },
];

export const benefitIncomeCriteria: IBenefitIncomeContext[] = [
  { category: BenefitIncomeType.ChildBenefit, context: 'Child Benefit' },
  { category: BenefitIncomeType.ChildTaxCredits, context: 'Child Tax Credits' },
  { category: BenefitIncomeType.WorkingTaxCredits, context: 'Working Tax Credits' },
  {
    category: BenefitIncomeType.DlaOrPip,
    context: 'Disability Living Allowance/Personal Independence Payments (DLA/PIP)',
  },
  { category: BenefitIncomeType.UniversalCredit, context: 'Universal Credit' },
];

export const expenditureForSubjectPropertyCriteria: IExpenditureForSubjectPropertyContext[] = [
  { category: ExpenditureForSubjectPropertyType.BoilerCover, context: 'Boiler Cover' },
  {
    category: ExpenditureForSubjectPropertyType.Certificates,
    context: 'Certificates (Insurance / safety certificates)',
  },
  { category: ExpenditureForSubjectPropertyType.GroundRentServiceCharge, context: 'Ground rent/ Service charge' },
  { category: ExpenditureForSubjectPropertyType.MonthlyPropertyMaintenance, context: 'Monthly property maintenance' },
];

export const otherIncomeCriteria: IOtherIncomeContext[] =
  [{ category: OtherIncomeType.RentalIncome, context: 'Rental Income' }];
