/* eslint-disable @typescript-eslint/naming-convention */

import { ProductFeeType } from '@msslib/constants';
import { ConditionalFeeScale } from './fee-scales';
import { ProductTransferInfoModel } from './products-transfer-info.ts';
import {
  ProductSourcingConditionType,
} from 'apps/clubhub/src/app/ignite/models/products/product-sourcing-condition-type';
import { OutcomeResults } from '@msslib/models/enums/outcome-results';
import { MortgageType } from './mortgage-type';
import { RepaymentMethod } from './repayment-method';
import { RateType } from './rate-type';

export enum Availability {
  AlsoAvailable = 1,
  OnlyAvailable = 2,
  No = 3,
  NotApplicable = 4,
}

export enum ProductTypeString {
  Fixed = 'Fixed',
  Discount = 'Discount',
  Variable = 'Variable',
  Tracker = 'Tracker',
  Lifetime = 'Lifetime',
  SteppedFixed = 'SteppedFixed',
  TrackerSonia = 'Tracker (SONIA)',
}

export enum ArrangementFeeType {
  Loan = 1,
  Property = 2,
}

export interface ResiBtlProductRate {
  ordinal: number;
  rate: number | null;
  variableRate: number | null;
  until: string | null;
  initialPeriod: number | null;
  rateType: RateType | null;
}


/* eslint-disable camelcase */
export interface Product {
  lender: string;
  lenderLogoUrl: string;
  productCode: string;
  productDescription: string;
  lendingType: string;
  isExclusive: boolean;
  isSecondCharge: boolean;
  direct: boolean | null;
  isAvailableFromPackagers: boolean;
  minLtv: number | null;
  maxLtv: number | null;
  repaymentTypes: RepaymentMethod[] | null;
  minLoanPolicy: number | null;
  maxLoanPolicy: number | null;
  appType: string | null;
  lenderAllowsTopSlicing: boolean;
  mortgageType: MortgageType[] | null;

  expatNotInUk: Availability | null;
  helpToBuy: Availability | null;
  hmo: Availability | null;
  holidayLetAirBb: Availability | null;
  jointBorrowerSoleProprietor: Availability | null;
  letToBuy: Availability | null;
  limitedCompanyBuyToLet: Availability | null;
  portfolioLandlord: Availability | null;
  regulatedBuyToLet: Availability | null;
  retirementInterestOnlyRio: boolean | null;
  rightToBuy: Availability | null;
  secondResidential: Availability | null;
  selfBuild: Availability | null;
  sharedOwnership: Availability | null;
  newBuild: Availability | null;
  greenEco: Availability | null;
  multiUnitFreeholdBlock: Availability | null;
  sharedEquity: Availability | null;
  offset: Availability | null;
  existingCustomer?: Availability | null;
  productTransfer?: Availability | null;
  firstTimeBuyer?: Availability | null;
  floorRate: number | null;
  cashback: number | null;
  purchaseCashback: number | null;
  remortgageCashback: number | null;
  freeLegal: boolean | null;
  freeVal: boolean | null;
  overpay: number | null;

  revertRate: number | null;
  revertRateType: string | null;
  revertRateMargin: number | null;
  rates: ResiBtlProductRate[];
  earlyRepaymentChargesErcApplied: boolean | null;
  ercRanks: number[] | null;
  fees: Partial<Record<ProductFeeType, number>>;
  isValuationFeeScaled: boolean;
  isValuationFeeRefer: boolean;

  aprc: number | null;
  trueCost: number | null;
  trueCostInitialPeriod: number | null;
  trueCostCustomPeriod: number | null;
  monthlyPaymentRevert: number | null;
  initialMonthlyPayment: number | null;
  totalInterestPayable: number | null;
  initialMonthlyPaymentIncFees: number | null;
  initialMonthlyPaymentInterestOnly: number | null;
  monthlyPaymentRevertInterestOnly: number | null;
  outstandingBalance: number | null;

  uniqueProductReference: string;
  productTransferEligible?: boolean;

  packager: string | null;
  lenderOnboard?: boolean;
  packagerOnboard?: boolean;
  ninetyDayValue?: number;
  hundredEightyDayValue?: number;
  notes: string | null;
  id: number | null;
  valRefundProductIncentive: boolean | null;
  valRefundProductIncentiveAmount: number | null;
  followOnDescription?: number | null;
  lenderOutcomeResult?: OutcomeResults;
  lenderAffordabilityMet?: string;

  baseValues?: Partial<Omit<Product, 'baseValues'>>;

  initialPeriod: number | null;
  initialRateValue: number | null;
  initialRateType: string | null;
  totalFees: number;
  forTerm: boolean | null;
}

export interface ProductSourcingRule {
  name: string;
  someLending: boolean;
  overrides: Partial<Product>;

  /** If `true`, this product rule was applied on the backend already and does not not to be re-applied. */
  isBackendRule: boolean;
}

export interface ProductSourcingRule {
  someLending: boolean;
  overrides: Partial<Product>;
}

export interface MatchedLenderProducts {
  lenderName: string;
  lenderLogoUrl: string;
  products: Product[];
  initialRateFrom: number | null;
  trueCostFrom: number | null;
  trueCostInitialPeriodFrom: number | null;
  monthlyPaymentFrom: number | null;
  updatedDate: string;
  isSecondCharge: boolean;
  lenderFeeScales: ConditionalFeeScale<ProductFeeType>[];
  sourcingRules: Record<keyof typeof ProductSourcingConditionType, ProductSourcingRule>;
  outcomeResult?: OutcomeResults;
  packagers: string[];
}

export interface LenderProductDetailsViewModel {
  lenderName: string;
  lenderUrl: string;
  affordabilityCalculatorUrl: string;
  buildingName: string;
  city: string;
  state: string;
  country: string;
  postcode: string;
  telephone: string;
  applicantMinimumAgeRange: string | null;
  applicantMaximumAgeRange: string | null;
  lastUpdated: string | null;
  baseRate: number;
  fullRateDescriptionParts: string[] | null;
}

export enum ProductDetailTableHeaders {
  lender = 'Lender Name',
  telephone = 'Lender Telephone Number',
  productCode = 'Product Code',
  productDescription = 'Product Description',
  selfBuild = 'Self Build',
  sharedOwnership = 'Shared Ownership',
  rate = 'Initial Rate',
  initialPeriod = 'Initial Period',
  fullRateDescription = 'Full rate description',
  initialMonthlyPayment = 'Initial Monthly Payment',
  initialMonthlyPaymentIncFees = 'Initial Monthly Payment inc. Fees',
  initialMonthlyPaymentInterestOnly = 'Initial Monthly Payment (Interest Only)',
  monthlyPaymentRevert = 'Monthly Payment after Initial Period',
  monthlyPaymentRevertInterestOnly = 'Monthly Payment after Initial Period (Interest Only)',
  totalInterestPayable = 'Total Interest Payable',
  trueCost = 'True Cost',
  aprc = 'APRC',
  maxLtv = 'Max LTV Available',
  revertRate = 'Revert Rate',
  capital_rest_period = 'Capital Rest Period', // leave blank as per Anish
  lastUpdated = 'Last Updated',
}

export enum ProductDetailBdgTableHeaders {
  lender = 'Lender Name',
  telephone = 'Lender Telephone Number',
  productCode = 'Product Code',
  productDescription = 'Product Description',
  selfBuild = 'Self Build',
  rate1 = 'Product Rate',
  rate1_initial_period = 'Product Period',
  monthly_rate = 'Monthly Payment',
  totalInterestPayable = 'Total Interest Payable',
  trueCost = 'True Cost',
  aprc = 'APRC',
  maxLtv = 'Max LTV Available',
  current_market_value = 'Current Market Value',
  ninetyDayValue = '90 Day Value',
  hundredEightyDayValue = '180 Day Value',
  revertRate = 'Revert Rate',
  lastUpdated = 'Last Updated',
}

export enum ProductConditionsTableHeaders {
  overpay = 'Max Overpayment 5 (No ERC)',
  firstTimeBuyer = 'Available to FTB',
  allows_purchase = 'Available to Purchase',
  allows_remortgage = 'Available to Remortgage',
  interest_only_allowed = 'Interest Only Available',
  helpToBuy = 'HTB',
  //applicantMinimumAgeRange = 'Applicant Minimum Age range',
  //applicantMaximumAgeRange = 'Applicant Maximum Age range',
}

export enum ProductConditionsBdgTableHeaders {
  firstTimeBuyer = 'Available to FTB',
  allows_purchase = 'Available to Purchase',
  allows_remortgage = 'Available to Remortgage',
  helpToBuy = 'HTB',
  //applicantMinimumAgeRange = 'Applicant Minimum Age range',
  //applicantMaximumAgeRange = 'Applicant Maximum Age range',
}


export enum MortgageRequirementTableHeaders {
  maxLoanPolicy = 'Max Loan',
  minLoanPolicy = 'Min Loan',
}

export enum FeesTableBdgHeaders {
  admin_fee = 'Administration Fee',
  arrangement_fee = 'Arrangement Fee',
  chaps_fee = 'Funds Transfer Fee (CHAPS)',
  conveyancing_fee = 'Legal Fee',
  avm_fee = 'AVM Fees',
  valuation_fee = 'Valuation Fee',
  product_fee_amount_payable = 'Total Fees',
}

export enum CashbackTableHeaders {
  cashback = 'Cashback',
  cashback_conditions = 'Cashback Conditions', // NEW: We will ask this for ESIS
}

export interface LenderProductTransferViewModel extends ProductTransferInfoModel {
  products: Product[];
  errorMessage: string;
  infoMessage: string;
  lenderName: string;
  initialPeriodDate: Date | string;
}
