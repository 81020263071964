import { InaccuracyReportCategory, InaccuracyReportSubCategory } from './enums';
import {
  MiProductsFiltersViewModel,
  ProductsBridgingModelRequest,
  ProductsBtlModelRequest,
  ProductsResidentialModelRequest,
  SearchType,
} from 'apps/shared/src/models';
import { LenderProduct } from '@msslib/models/mi';
import { ProductResultsConfigurationRequestModel } from 'apps/clubhub/src/app/ignite/models/products';


export interface IInaccuracyReport {
  groupId?: number;
  categoryId: InaccuracyReportCategory;
  subCategoryId?: InaccuracyReportSubCategory;
  report?: string;
  lenderName: string;
  productDescription?: string;
}

export enum InaccuracyReportType
{
  Ignite = 0,
  Audit = 1,
  Products = 2,
  ProductsAudit = 3,
}

export interface IInaccuracyReportRequest {
  affordabilitySearchId?: string | undefined | null;
  productsSearchId?: string | undefined | null;
  criteriaSearchId?: string | undefined | null;
  propertySearchId?: string | undefined | null;
  // lenderName?: string;
  reports?: IInaccuracyReport[];
  reportType?: InaccuracyReportType;
  lendingType?: string;
  source: SearchType;
  agencyNumber?: number | null;
  criteriaV2: boolean;
  productsFilters: MiProductsFiltersViewModel[];
  products: LenderProduct[];
  productsSearchRequest: ProductsResidentialModelRequest | ProductsBtlModelRequest
    | ProductsBridgingModelRequest | undefined;
  productResultsConfiguration: ProductResultsConfigurationRequestModel | undefined;
}
