<form [formGroup]="form" (ngSubmit)="onSubmit()">

        <ng-container formArrayName="fees">
            <ng-container *ngFor="let section of sections">
                <h2 class="my-4">{{section}}</h2>
                <table class="fee-table">
                    <tr class="header-row">
                        <th>Fee Name</th>
                        <th>Description</th>
                        <th>Fee</th>
                        <th>Lending Type</th>
                        <th>Fee Configuration</th>
                    </tr>
                    <tr *ngFor="let fee of feeArray?.controls; let i = index" [formGroupName]="i">
                        <ng-container *ngIf="section===feeArray.controls[i].value.section">
                            <td class="name">
                                {{feeArray.controls[i].value.name}}
                                <div class="button-container">
                                    <button *ngIf="feeArray.controls[i].value.duplicated"
                                        type="button"
                                        class="btn btn-2022--secondary"
                                        (click)="removeAdditionalLine(i)"
                                        [disabled]="disabled">
                                        Remove Additional Line
                                    </button>
                                    <button *ngIf="!feeArray.controls[i].value.duplicated"
                                        type="button"
                                        class="btn btn-2022--secondary"
                                        (click)="addAdditionalLine(fee.value, i)"
                                        [disabled]="disabled">
                                        Add Additional Line
                                    </button>
                                </div>
                            </td>
                            <td class="description" [innerHTML]="feeArray.controls[i].value.description"></td>
                            <td class="amount">
                                <input
                                    [class.is-invalid]="invalid(['fees',i, 'amount']) && errors(['fees',i, 'amount']).max"
                                    class="form-control"
                                    type="textbox"
                                    id="amount"
                                    formControlName="amount"
                                    placeholder="0.00"
                                    appTwoDigitDecimalNumber
                                    data-testid="lender_tariff_fee_amount_input"
                                >
                                <div *ngIf="invalid(['fees',i, 'amount']) && errors(['fees',i, 'amount']).max" class="text-danger">
                                    Fee amount must be less than £100,000
                                </div>
                            </td>
                            <td class="amount">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        [value]="null"
                                        type="radio"
                                        [id]='"lending_type" + i'
                                        formControlName="lendingTypeId"
                                        [attr.data-testid]='"lender_tariff_lending_type_radio_" + i'
                                        [class.is-invalid]="invalid(['fees']) && errors(['fees'])?.duplicateLendingType === feeArray.controls[i].value.name"
                                    >
                                    <label class="form-check-label">
                                        All
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        [value]="resiId"
                                        type="radio"
                                        [id]='"lending_type" + i'
                                        formControlName="lendingTypeId"
                                        [attr.data-testid]='"lender_tariff_lending_type_radio_" + i'
                                        [class.is-invalid]="invalid(['fees']) && errors(['fees'])?.duplicateLendingType === feeArray.controls[i].value.name"
                                    >
                                    <label class="form-check-label">
                                        Residential
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        [value]="btlId"
                                        type="radio"
                                        [id]='"lending_type" + i'
                                        formControlName="lendingTypeId"
                                        [attr.data-testid]='"lender_tariff_lending_type_radio_" + i'
                                        [class.is-invalid]="invalid(['fees']) && errors(['fees'])?.duplicateLendingType === feeArray.controls[i].value.name"
                                    >
                                    <label class="form-check-label">
                                        Buy To Let
                                    </label>
                                </div>
                                <div *ngIf="invalid(['fees']) && errors(['fees'])?.duplicateLendingType === feeArray.controls[i].value.name" class="text-danger">
                                    Lending Type is overlapping.
                                </div>
                            </td>
                            <td  class="configuration">
                                <div formGroupName="feeConfiguration">
                                    <div class="form-group">
                                        <input
                                            [class.is-invalid]="invalid(['fees',i]) && errors(['fees',i]).invalidFeeConfiguration"
                                            [class.is-invalid]="invalid(['fees']) && checkDuplicationError(i)"
                                            type="checkbox"
                                            class="form-check-input me-1"
                                            formControlName="purchase"
                                            data-testid="lender_tariff_purchase_input"
                                        >
                                        <label class="form-check-label">Purchase</label>
                                    </div>
                                    <div class="form-group">
                                        <input
                                            [class.is-invalid]="invalid(['fees',i]) && errors(['fees',i]).invalidFeeConfiguration"
                                            [class.is-invalid]="invalid(['fees']) && checkDuplicationError(i)"
                                            type="checkbox"
                                            class="form-check-input me-1"
                                            formControlName="remortgage"
                                            data-testid="lender_tariff_remortgage_input"
                                        >
                                        <label class="form-check-label">Remortgage</label>
                                    </div>
                                    <div class="form-group">
                                        <input
                                            [class.is-invalid]="invalid(['fees',i]) && errors(['fees',i]).invalidFeeConfiguration"
                                            [class.is-invalid]="invalid(['fees']) && checkDuplicationError(i)"
                                            type="checkbox"
                                            class="form-check-input me-1"
                                            formControlName="productTransfer"
                                            data-testid="lender_tariff_product_transfer_input"
                                        >
                                        <label class="form-check-label">Product Transfer</label>
                                    </div>
                                    <div class="form-group">
                                        <input
                                            [class.is-invalid]="invalid(['fees',i]) && errors(['fees',i]).invalidFeeConfiguration"
                                            [class.is-invalid]="invalid(['fees']) && checkDuplicationError(i)"
                                            type="checkbox"
                                            class="form-check-input me-1"
                                            formControlName="furtherAdvance"
                                            data-testid="lender_tariff_further_advance_input"
                                        >
                                        <label class="form-check-label">Further Advance</label>
                                    </div>
                                    <div class="form-group">
                                        <input
                                            [class.is-invalid]="invalid(['fees',i]) && errors(['fees',i]).invalidFeeConfiguration"
                                            [class.is-invalid]="invalid(['fees']) && checkDuplicationError(i)"
                                            type="checkbox"
                                            class="form-check-input me-1"
                                            formControlName="existingCustomer"
                                            data-testid="lender_tariff_existing_customer_input"
                                        >
                                        <label class="form-check-label">Existing Customer</label>
                                    </div>
                                    <div class="form-group">
                                        <input
                                            [class.is-invalid]="invalid(['fees',i]) && errors(['fees',i]).invalidFeeConfiguration"
                                            [class.is-invalid]="invalid(['fees']) && checkDuplicationError(i)"
                                            type="checkbox"
                                            class="form-check-input me-1"
                                            formControlName="secondCharge"
                                            data-testid="lender_tariff_second_charge_input"
                                        >
                                        <label class="form-check-label">Second Charge</label>
                                    </div>
                                </div>
                                <div *ngIf="invalid(['fees',i]) && errors(['fees',i]).invalidFeeConfiguration" class="text-danger">
                                    At least one option is required.
                                </div>
                                <div *ngIf="invalid(['fees']) && checkDuplicationError(i)" class="text-danger">
                                    Duplicate Fee Configuration
                                </div>
                            </td>
                        </ng-container>
                    </tr>
                </table>
            </ng-container>
        </ng-container>
    <div class="d-flex justify-content-end">
        <button type="submit" class="btn btn-2022--secondary mt-3" data-testid="lender_tariff_submit_input">Submit</button>
    </div>
</form>

<ng-template #lendingTypeModalTemplateRef>
    <p>
        The following action will create a separate fee for Residential and Buy to Let products. Ok to proced?
    </p>
    <div class="text-end">
        <a class="btn btn-2022--secondary me-2" (click)="duplicateFee(); modalService.close()">
            OK
        </a>
        <a class="btn btn-2022--secondary me-2" (click)="modalService.close();">
            Not Required
        </a>
        <a class="btn btn-2022--secondary" (click)="setDefaultLendingType(); modalService.close();">
            Cancel
        </a>
    </div>
</ng-template>
