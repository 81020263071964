/* eslint-disable no-use-before-define */
import { Injectable } from '@angular/core';
import {
  ClientRectObject,
  Placement,
  VirtualElement,
  arrow,
  autoUpdate,
  computePosition,
  flip,
  offset,
  shift,
} from '@floating-ui/dom';
import { Subject } from 'rxjs';

export interface ShepherdStep {
  /** HTML body for this step. */
  body: string;

  /** Preferred placement of the popover. */
  placement?: Placement;

  /** Elements that will be highlighted during this step. Can be elements themselves or a selector. */
  elements: string | HTMLElement | HTMLElement[];
}

export class Shepherd {

  private stepIndex: number;
  private elements?: Record<'popover' | 'arrow' | 'body' | 'prevButton' | 'nextButton' | 'highlight', HTMLElement>;
  private floatingUiCleanup?: () => void;
  private activatedStepsCount = 0;

  public onDestroyed$ = new Subject<number>();

  public constructor(
    private readonly steps: ShepherdStep[],
  ) {}

  public start() {
    this.activatedStepsCount = 0;
    this.destroy();
    this.createPopover();
    this.changeStep(0);
  }

  public changeStep(stepIndex: number) {
    if (!this.elements) {
      throw new Error('Tried to change step when popover has not been created.');
    }

    const { popover: popoverElement, arrow: arrowEl, body, nextButton, prevButton, highlight } = this.elements;

    this.floatingUiCleanup?.();

    const step = this.steps[stepIndex];

    body.innerHTML = step.body;

    const prevDisabled = stepIndex <= 0;
    prevButton.toggleAttribute('disabled', prevDisabled);

    const nextDisabled = stepIndex >= this.steps.length - 1;
    nextButton.toggleAttribute('disabled', nextDisabled);

    // If both buttons are disabled, hide them both
    prevButton.style.display = nextButton.style.display = prevDisabled && nextDisabled ? 'none' : 'block';

    const referenceElement = this.getReferenceElement(step.elements);

    this.floatingUiCleanup = autoUpdate(referenceElement, popoverElement, async () => {
      const { x, y, placement, middlewareData } = await computePosition(referenceElement, popoverElement, {
        placement: step.placement,
        middleware: [
          flip(),
          shift(),
          offset({ mainAxis: 10 }),
          arrow({ element: arrowEl, padding: 4 }),
        ],
      });

      // Update popover position
      Object.assign(popoverElement.style, {
        left: `${x}px`,
        top: `${y}px`,
      });

      if (referenceElement.contextElement) {
        const rect = referenceElement.contextElement.getBoundingClientRect();
        //hide the highlight temporarily so that we can check if the reference element is the top element
        highlight.style.visibility = 'hidden';

        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        const topElement = document.elementFromPoint(centerX, centerY);

        // if the reference element is not on top hide all the floating elements
        if (topElement !== referenceElement.contextElement &&
          referenceElement.contextElement?.contains(topElement) === false) {
          Object.assign(popoverElement.style, {
            visibility : 'hidden',
          });
        } else {
          Object.assign(popoverElement.style, {
            visibility : 'visible',
          });
          highlight.style.visibility = 'visible';
        }
      }

      // Update popover arrow position
      if (middlewareData.arrow) {
        const staticSide = {
          top: 'bottom',
          right: 'left',
          bottom: 'top',
          left: 'right',
        }[placement.split('-')[0]] as string;

        const rotationAmount = {
          top: 135,
          right: 225,
          bottom: 315,
          left: 45,
        }[placement.split('-')[0]] as number;

        const { x: arrowX, y: arrowY } = middlewareData.arrow;
        Object.assign(arrowEl.style, {
          left: arrowX !== null ? `${arrowX}px` : '',
          top: arrowY !== null ? `${arrowY}px` : '',
          right: '',
          bottom: '',
          [staticSide]: '-8px',
          transform: `rotate(${rotationAmount}deg)`,
        });
      }

      // Update highlight element position
      const { top, left, width, height } = referenceElement.getBoundingClientRect();
      const padding = 6;
      highlight.style.top = `${top - padding}px`;
      highlight.style.left = `${left - padding}px`;
      highlight.style.width = `${width + padding * 2}px`;
      highlight.style.height = `${height + padding * 2}px`;
    }, { animationFrame: true });

    this.stepIndex = stepIndex;
    // Save max step number in case when we back to prev state
    this.activatedStepsCount = Math.max(this.activatedStepsCount, (stepIndex + 1));
  }

  public prevStep() {
    if (this.stepIndex > 0) {
      this.changeStep(this.stepIndex - 1);
    }
  }

  public nextStep() {
    if (this.stepIndex < this.steps.length - 1) {
      this.changeStep(this.stepIndex + 1);
    }
  }

  /**
   * Destroys this Shepherd instance, cleaning up DOM elements and functions.
   * This function is idempotent - i.e. safe to call even if the Shepherd has not been started.
   */
  public destroy() {
    this.floatingUiCleanup?.();
    this.floatingUiCleanup = undefined;

    if (this.elements) {
      const { popover, highlight } = this.elements;
      popover.classList.remove('show');
      highlight.classList.remove('show');
      this.elements = undefined;

      // eslint-disable-next-line spellcheck/spell-checker
      popover.addEventListener('transitionend', () => {
        document.body.removeChild(popover);
        document.body.removeChild(highlight);
      });

      if (this.activatedStepsCount) {
        this.onDestroyed$.next(this.activatedStepsCount);
      }
    }
  }

  /**
   * Gets the element to be used as a reference element based on the given 'elements' step config option.
   * If multiple elements are given, the result will be a virtual element with a bounding box containing all of them.
   */
  private getReferenceElement(elementsOrSelector: ShepherdStep['elements']): VirtualElement {
    if (typeof elementsOrSelector === 'string') {
      return {
        getBoundingClientRect: () => {
          const elements = Array.from(document.body.querySelectorAll<HTMLElement>(elementsOrSelector));
          return elements.length === 1
            ? elements[0].getBoundingClientRect()
            : this.getBoundingBoxMultiple(elements);
        },
        contextElement: Array.from(document.body.querySelectorAll<HTMLElement>(elementsOrSelector))[0],
      };
    }

    if (Array.isArray(elementsOrSelector)) {
      if (elementsOrSelector.length === 1) {
        return elementsOrSelector[0];
      }

      // If multiple elements, create a virtual element that will have a bounding box covering all elements
      return {
        getBoundingClientRect: () => this.getBoundingBoxMultiple(elementsOrSelector),
      };
    }

    return elementsOrSelector;
  }

  private getBoundingBoxMultiple(elements: HTMLElement[]): ClientRectObject {
    const groupBoundingBox = { top: Infinity, left: Infinity, bottom: -Infinity, right: -Infinity };
    for (const element of elements) {
      const boundingBox = element.getBoundingClientRect();
      groupBoundingBox.top = Math.min(groupBoundingBox.top, boundingBox.top);
      groupBoundingBox.left = Math.min(groupBoundingBox.left, boundingBox.left);
      groupBoundingBox.bottom = Math.max(groupBoundingBox.bottom, boundingBox.bottom);
      groupBoundingBox.right = Math.max(groupBoundingBox.right, boundingBox.right);
    }

    return {
      ...groupBoundingBox,
      x: groupBoundingBox.left,
      y: groupBoundingBox.top,
      width: groupBoundingBox.right - groupBoundingBox.left,
      height: groupBoundingBox.bottom - groupBoundingBox.top,
    };
  }

  private createPopover() {
    const popover = createElement(document.body, 'div', ['sf-shepherd']);
    setTimeout(() => popover.classList.add('show'), 1); // fade in

    const arrow = createElement(popover, 'div', ['sf-shepherd-arrow']);

    const body = createElement(popover, 'div', ['sf-shepherd-body']);

    const prevButton = createElement(
      popover, 'button', ['btn', 'btn-2022--primary-lightest', 'sf-shepherd-prev-btn', 'me-1'],
    );
    prevButton.innerText = 'Previous';
    prevButton.addEventListener('click', () => this.prevStep());

    const nextButton = createElement(
      popover, 'button', ['btn', 'btn-2022--primary-lightest', 'sf-shepherd-next-btn', 'ms-1'],
    );
    nextButton.innerText = 'Next';
    nextButton.addEventListener('click', () => this.nextStep());

    const closeButton = createElement(popover, 'button', ['btn-close', 'sf-shepherd-close-btn']);
    closeButton.addEventListener('click', () => this.destroy());

    const highlight = createElement(document.body, 'div', ['sf-shepherd-highlight']);
    setTimeout(() => highlight.classList.add('show'), 1); // fade in

    this.elements = { popover, arrow, body, prevButton, nextButton, highlight };
  }
}

@Injectable({
  providedIn: 'root',
})
export class ShepherdService {
  public create(steps: ShepherdStep[]) {
    const context = new Shepherd(steps);
    return context;
  }
}

function createElement(parent: Node, tagName: string, classes: string[]) {
  const el = document.createElement(tagName);
  el.classList.add(...classes);
  parent.appendChild(el);
  return el;
}
