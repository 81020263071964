import { animate, state, style, transition, trigger } from '@angular/animations';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class Animations {
  public static get openClose() {
    return trigger('openClose', [
      state(
        'open',
        style({
          opacity: '1',
          height: '*',
          pointerEvents: 'auto',
        }),
      ),
      state(
        'closed',
        style({
          height: '0',
          opacity: '0',
          pointerEvents: 'none',
        }),
      ),
      transition('open => closed', animate('200ms')),
      transition('closed => open', animate('200ms')),
    ]);
  }

  public static get tableOpenClose() {
    return trigger('tableOpenClose', [
      state(
        'open',
        style({
          opacity: '1',
          height: '*',
          display: 'table-row',
        }),
      ),
      state(
        'closed',
        style({
          height: '0',
          opacity: '0',
          display: 'none',
        }),
      ),
      transition('open => closed', animate('200ms')),
      transition('closed => open', animate('200ms')),
    ]);
  }

  public static get slideInOut() {
    return trigger('slideInOut', [
      transition(
        ':enter', [
          style({ transform: 'translateY(-100%)' }),
          animate('200ms', style({ transform: 'translateY(0)' })),
        ]),
      transition(
        ':leave', [
          style({ transform: 'translateY(0)' }), animate('200ms', style({ transform: 'translateY(-100%)' })),
        ]),
    ]);
  }
}
