/* eslint-disable camelcase */
import { Injectable } from '@angular/core';
import {
  AffordabilityRequest,
  BdgAffordabilityRequest,
  BtlAffordabilityRequest,
} from 'apps/clubhub/src/app/ignite/models/affordability';
import {
  BridgingProduct,
  Loan,
  LoanPeriod,
  LoanPeriodCalculation,
  LoanType,
  MethodOfRepayment,
  MonthlyCalculation,
  Product,
  ProductsBridgingModelRequest,
  ProductsBtlModelRequest,
  ProductsResidentialModelRequest,
  RateType,
  RepaymentMethod,
} from 'apps/shared/src/models';
import { CustomBridgingProductFees, CustomFee, CustomProductFees } from '@msslib/models/custom-product-calculations';

export interface CalculationResult {
  trueCost: number;
  trueCostInitial: number | null;
  trueCostCustom: number | null;
  initialMonthlyPayment: number;
  monthlyPaymentRevert: number;
  isForTerm: boolean;
  outstandingBalance: number;
}

export interface FullCalculationResult extends CalculationResult {
  loanPeriodCalculations: LoanPeriodCalculation[];
  aprc: number;
  totalInterestPayable: number;
}

class MonthlyPaymentCalculations {
  public constructor(
      public monthlyPayments: number,
      public principalRepaid: number,
      public monthlyPaymentsIo: number,
      public principalRepaidIo: number,
      public term: number,
      public isRevert: boolean,
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class ProductsCalculationService {

  private maxAprcIterations = 16;

  public performLoanCalculation(loan: Loan, customTrueCostPeriod: number | null): FullCalculationResult {
    const loanPeriodCalculations = this.performLoanPeriodCalculations(loan);
    const monthlyPaymentCalculations = this.performMonthlyCalculations(loan, loanPeriodCalculations);

    const aprc: number = this.calculateAPRC(loan);

    const trueCost: number = this.calculateTrueCost(monthlyPaymentCalculations, loan);

    const trueCostInitial: number | null =
    (loan.loanPeriods[0]?.term || loan.loanPeriods[0].term > 0) ?
      this.calculateTrueCostInitial(loan, loanPeriodCalculations, null)
      : null;

    const trueCostCustom: number | null =
      this.calculateTrueCostInitial(loan, loanPeriodCalculations, customTrueCostPeriod);

    const totalInterestPayable: number = this.calculateTotalInterestPayable(monthlyPaymentCalculations);
    const isForTerm = loan.loanPeriods[0].term < 1;
    return this.toResult(
      { loanPeriodCalculations,
        trueCost,
        trueCostInitial,
        trueCostCustom,
        aprc,
        totalInterestPayable,
        isForTerm,
        outstandingBalance: 0 });
  }

  private calculateAPRC(loan: Loan) {
    const aprcLoanModel = { ...loan } as Loan;

    if (aprcLoanModel.loanPeriods.some(x => x.rateType === RateType.Fixed)) {
      const maxFixedRepaymentRate = Math.max(
        ...loan.loanPeriods.filter(x => x.rateType === RateType.Fixed).map(x => x.repaymentRate));
      const maxFixedInterestOnlyRate = Math.max(
        ...loan.loanPeriods.filter(x => x.rateType === RateType.Fixed).map(x => x.interestOnlyRate ?? 0));
      const revertRate = loan.loanPeriods.find(x => x.term === -1);

      if (revertRate && revertRate.repaymentRate < maxFixedRepaymentRate) {
        aprcLoanModel.loanPeriods = aprcLoanModel.loanPeriods.map(x =>
          x.term === -1 ? { ...x, repaymentRate: maxFixedRepaymentRate } : x);
      }

      if (revertRate && revertRate.repaymentRate < maxFixedInterestOnlyRate) {
        aprcLoanModel.loanPeriods = aprcLoanModel.loanPeriods.map(x =>
          x.term === -1 ? { ...x, interestOnlyRate: maxFixedInterestOnlyRate } : x);
      }
    }

    const loanPeriodCalculations = this.performLoanPeriodCalculations(aprcLoanModel);
    const monthlyPaymentCalculations = this.performMonthlyCalculations(aprcLoanModel, loanPeriodCalculations);
    const targetPvSum = loan.repaymentLoanAmount
      + loan.interestOnlyLoanAmount
      + loan.loanFees
      + loan.cashback
      - loan.additionalFees;

    let aprcLowerBound = 0;
    let aprcUpperBound = 1;
    let aprc: number;

    let numIterations = 0;

    do {
      aprc = (aprcLowerBound + aprcUpperBound) / 2;
      const sumOfPv: number = monthlyPaymentCalculations
        .map((payment, monthIndex) => {
          const discFactor = Math.pow(1 / (1 + aprc), (monthIndex + 1) / 12);
          return (payment.repaymentTotalRepayment + payment.interestOnlyCapitalRepaid +
            (payment.interestOnlyLoanOsAtBeginningOfMonth * payment.interestOnlyInterest)) * discFactor;
        }).reduce((accumulator, current) => {
          return accumulator + current;
        }, 0);

      if (Math.abs(sumOfPv - targetPvSum) === 0) {
        break;
      }

      if (sumOfPv < targetPvSum) {
        aprcUpperBound = aprc;
      } else {
        aprcLowerBound = aprc;
      }
    } while (++numIterations < this.maxAprcIterations);
    return aprc;
  }

  public fastCalculate(model: Loan, calculateTrueCostOverMonths: number | null = null): CalculationResult {
    const loanAmount = model.repaymentLoanAmount + (model.loanType === LoanType.InterestOnly ? 0 : model.loanFees);

    const loanAmountIo = model.interestOnlyLoanAmount + (model.loanType === LoanType.InterestOnly ? model.loanFees : 0);

    const monthlyPeriodPayments = this.calculateMonthlyPeriodPayments(model, loanAmount, loanAmountIo);

    const monthlyPaymentRevert = monthlyPeriodPayments[monthlyPeriodPayments.length - 1].monthlyPayments;
    const monthlyPaymentRevertIo = monthlyPeriodPayments[monthlyPeriodPayments.length - 1].monthlyPaymentsIo;

    const oneOffPayments = model.additionalFees - model.cashback;

    const trueCost = monthlyPeriodPayments
      .reduce((acc, val) => acc + (val.monthlyPayments + val.monthlyPaymentsIo) * val.term, 0) +
        (monthlyPaymentRevert + monthlyPaymentRevertIo)
        * Math.max(model.term - monthlyPeriodPayments.reduce((acc, val) => acc + val.term, 0), 0) +
        oneOffPayments + loanAmountIo;

    const trueCostInitial = (model.loanPeriods[0].term > 0) ?
      (monthlyPeriodPayments[0].monthlyPayments + monthlyPeriodPayments[0].monthlyPaymentsIo)
        * model.loanPeriods[0].term + oneOffPayments : null;

    const trueCostInitialCustom = (monthlyPeriodPayments[0].monthlyPayments +
        monthlyPeriodPayments[0].monthlyPaymentsIo) * (calculateTrueCostOverMonths ?? model.loanPeriods[0].term) +
        oneOffPayments;

    const isForTerm = model.loanPeriods[0].term < 1;
    const outstandingBalance = this.calculateOutstandingBalance(model, loanAmount, loanAmountIo);

    return {
      trueCost: trueCost,
      trueCostInitial: trueCostInitial,
      trueCostCustom: trueCostInitialCustom,
      initialMonthlyPayment: monthlyPeriodPayments[0].monthlyPayments + monthlyPeriodPayments[0].monthlyPaymentsIo,
      monthlyPaymentRevert: monthlyPaymentRevert + monthlyPaymentRevertIo,
      isForTerm,
      outstandingBalance: outstandingBalance,
    };
  }

  public calculateMonthlyPeriodPayments(model: Loan, loanAmount: number, loanAmountIo: number):
    MonthlyPaymentCalculations[] {
    const monthlyPeriodPayments: MonthlyPaymentCalculations[] = [];
    let totalPrincipalRepaid = 0;
    let totalTermElapsed = 0;
    for (const period of model.loanPeriods) {
      if (totalTermElapsed >= model.term) {
        break;
      }
      const isRevert = period.term === -1;
      let term = period.term === -1 ?
        model.term - model.loanPeriods.filter(l => l.term !== -1).reduce((acc, val) => acc + val.term, 0) :
        period.term;

      term = Math.min(model.term - totalTermElapsed, term);

      const rate = period.repaymentRate / 12;
      const monthlyPayments = rate * (loanAmount - totalPrincipalRepaid)
            / (1 - Math.pow(1 + rate, -(model.term - totalTermElapsed)));
      const monthlyPaymentsIo = loanAmountIo * (period.interestOnlyRate ?? 0) / 12;
      const principalRepaid = (monthlyPayments - rate * (loanAmount - totalPrincipalRepaid)) *
            (Math.pow(1 + rate, term) - 1) / rate;
      const principalRepaidIo = monthlyPaymentsIo * term;

      totalPrincipalRepaid += principalRepaid;
      totalTermElapsed += term;
      monthlyPeriodPayments.push({
        monthlyPayments: monthlyPayments,
        principalRepaid: principalRepaid,
        monthlyPaymentsIo: monthlyPaymentsIo,
        principalRepaidIo: principalRepaidIo,
        term: term,
        isRevert: isRevert,
      });
    }

    return monthlyPeriodPayments;
  }

  public calculateOutstandingBalance(model: Loan, loanAmount: number, loanAmountIo: number):
  number {
    let totalPrincipalRepaid = 0;
    let totalTermElapsed = 0;
    const calculatedLoanPeriods: LoanPeriod[] = [];
    let outstandingBalance = loanAmount + loanAmountIo;
    for (let i = 0; i <  model.loanPeriods.length; i++) {
      const period = model.loanPeriods[i];
      if (period.term === -1) {
        break;
      }

      const loanPeriod = Math.floor((period.term + 2) / 12) * 12;

      if (loanPeriod > period.term) {
        calculatedLoanPeriods.push({
          repaymentRate: period.repaymentRate,
          term: period.term,
          interestOnlyRate: period.interestOnlyRate,
          rateType: period.rateType,
          until: period.until,
        } as LoanPeriod);

        calculatedLoanPeriods.push({
          repaymentRate: model.loanPeriods[i + 1].repaymentRate,
          term: loanPeriod - period.term,
          interestOnlyRate: model.loanPeriods[i + 1].interestOnlyRate,
          rateType: model.loanPeriods[i + 1].rateType,
        } as LoanPeriod);
      }  else {
        calculatedLoanPeriods.push({
          repaymentRate: period.repaymentRate,
          term: loanPeriod,
          interestOnlyRate: period.interestOnlyRate,
          rateType: period.rateType,
          until: period.until,
        } as LoanPeriod);
      }
    }

    const totalCalculatedTerm = calculatedLoanPeriods.reduce((acc, val) => acc + val.term, 0);

    if (totalCalculatedTerm >= model.term) {
      return 0; //If the initial periods are longer than the loan period there will be no outstanding balance left
    }

    for (const period of calculatedLoanPeriods) {
      const rate = period.repaymentRate / 12;
      const monthlyPayments = rate * (loanAmount - totalPrincipalRepaid)
          / (1 - Math.pow(1 + rate, -(model.term - totalTermElapsed)));
      const principalRepaid = (monthlyPayments - rate * (loanAmount - totalPrincipalRepaid)) *
          (Math.pow(1 + rate, period.term) - 1) / rate;

      totalPrincipalRepaid += principalRepaid;
      totalTermElapsed += period.term;
      outstandingBalance -= principalRepaid;
    }

    return outstandingBalance;
  }

  public performBridgingLoanCalculation(loan: Loan, model: BdgAffordabilityRequest
    | ProductsBridgingModelRequest, product: BridgingProduct): FullCalculationResult {
    const loanPeriodCalculations = this.performLoanPeriodCalculations(loan);
    const monthlyPaymentCalculations = this.performMonthlyCalculations(loan, loanPeriodCalculations);

    const targetPvSum =
      loan.interestOnlyLoanAmount
        + loan.loanFees
        + loan.cashback
        - loan.additionalFees;

    let aprcLowerBound = 0;
    let aprcUpperBound = 1;
    let aprc: number;

    let numIterations = 0;

    do {
      aprc = (aprcLowerBound + aprcUpperBound) / 2;
      const sumOfPv: number = monthlyPaymentCalculations
        .map((payment, monthIndex) => {
          const discFactor = Math.pow(1 / (1 + aprc), (monthIndex + 1) / 12);
          return (payment.repaymentTotalRepayment + payment.interestOnlyCapitalRepaid +
            (payment.interestOnlyLoanOsAtBeginningOfMonth * payment.interestOnlyInterest)) * discFactor;
        }).reduce((accumulator, current) => {
          return accumulator + current;
        }, 0);

      if (Math.abs(sumOfPv - targetPvSum) === 0) {
        break;
      }

      if (sumOfPv < targetPvSum) {
        aprcUpperBound = aprc;
      } else {
        aprcLowerBound = aprc;
      }
    } while (++numIterations < this.maxAprcIterations);


    const trueCostInitial: number | null = this.calculateTrueCostInitial(loan, loanPeriodCalculations, null);

    const totalInterestPayable: number = this.calculateTotalInterestPayable(monthlyPaymentCalculations);

    let trueCost = 0;
    const isForTerm = false;

    switch (model.methodOfRepayment) {
      case MethodOfRepayment.Serviced:
        trueCost = this.calculateTrueCost(monthlyPaymentCalculations, loan);
        if (model.loanAmount) {
          product.grossLoan = loan.loanFees + model.loanAmount;
        }
        return this.toResult({
          loanPeriodCalculations,
          trueCost,
          trueCostInitial,
          trueCostCustom: 0,
          aprc,
          totalInterestPayable,
          isForTerm,
          outstandingBalance: 0,
        });

      case MethodOfRepayment.RolledUp:
      case MethodOfRepayment.Retained:
        // Perform custom Rolled Up calculation
        const interestRate = product.rates[0].rate / 100;
        const termTime = model.loanTerm ?? 0; //Months

        let startingBalance = loan.interestOnlyLoanAmount + loan.loanFees;
        let interest = 0;

        for (let i = 0; i < termTime; i++) {
          const interestPayable = startingBalance * interestRate;
          startingBalance += interestPayable;
          interest += interestPayable;
        }

        trueCost = loan.interestOnlyLoanAmount + interest + loan.loanFees + loan.additionalFees;
        product.grossLoan = loan.interestOnlyLoanAmount + interest + loan.loanFees;
        return this.toResult({
          loanPeriodCalculations,
          trueCost,
          trueCostInitial,
          trueCostCustom: 0,
          aprc,
          totalInterestPayable,
          isForTerm,
          outstandingBalance: 0,
        });

      // case MethodOfRepayment.Retained:
      //   trueCost = this.calculateTrueCost(monthlyPaymentCalculations, loan);
      //   product.grossLoan = loan.interestOnlyLoanAmount + totalInterestPayable + loan.loanFees;
      //   return { loanPeriodCalculations, trueCost, trueCostInitial, aprc, totalInterestPayable };
    }

    return this.toResult({
      loanPeriodCalculations,
      trueCost,
      trueCostInitial,
      trueCostCustom: 0,
      aprc,
      totalInterestPayable,
      isForTerm,
      outstandingBalance: 0,
    });
  }

  public performCalculations(
    products: Product[],
    model: AffordabilityRequest | BtlAffordabilityRequest | BdgAffordabilityRequest
      | ProductsResidentialModelRequest | ProductsBtlModelRequest | ProductsBridgingModelRequest,
    includeCashback = true,
    fees: CustomProductFees [] | null = null,
    customTrueCostPeriod: number | null = null,
    customFees: CustomFee[],
    assumedLegalFee: number | null,
    useFullCalculations: boolean = false,
  ) {
    if (this.isModelApplicableForCalculation(model)) {
      products.forEach(product => {
        const lastPeriod = product.rates.reduce(
          (prev, current) => {
            return prev.ordinal > current.ordinal ? prev : current;
          },
        );
        if ((lastPeriod.initialPeriod || lastPeriod.until) && !product.revertRate) {
          return;
        }
        try {
          const loan = Loan.fromProduct(product, model, includeCashback, fees, customFees, assumedLegalFee);
          if (useFullCalculations) {
            const result = this.performLoanCalculation(loan, customTrueCostPeriod);
            this.setFullCalculatedValues(product, result);
          } else {
            const result = this.fastCalculate(loan, customTrueCostPeriod);
            this.setCalculatedValues(product, result);
          }
        } catch {
          // Can happen if a product has invalid data. Not logging to Sentry as we can get several errors per search.
        }
      });
    }
  }

  public performBridgingCalculations(
    products: BridgingProduct[],
    model: BdgAffordabilityRequest
      | ProductsBridgingModelRequest,
    fees: CustomBridgingProductFees[] | null = null,
  ): BridgingProduct[] {
    products.forEach(product => {
      try {
        const loan = Loan.fromBridgingProduct(product, model, fees);
        const result = this.performBridgingLoanCalculation(loan, model, product);
        this.setBridgingCalculatedValues(model, product, result);
      } catch {
        // Can happen if a product has invalid data. Not logging to Sentry as we can get several errors per search.
      }
    });
    return [];
  }

  private setFullCalculatedValues(product: Product, calculationResult: FullCalculationResult) {
    const {
      loanPeriodCalculations, trueCost, trueCostInitial, trueCostCustom, aprc, totalInterestPayable,
    } = calculationResult;
    const displayTrueCost = +trueCost.toFixed(2);
    product.initialMonthlyPayment =
      loanPeriodCalculations[0].repaymentInitialMonthlyRepayments
      + loanPeriodCalculations[0].interestOnlyInitialMonthlyRepayments;
    product.monthlyPaymentRevert =
      loanPeriodCalculations[loanPeriodCalculations.length - 1].repaymentInitialMonthlyRepayments
      + loanPeriodCalculations[loanPeriodCalculations.length - 1].interestOnlyInitialMonthlyRepayments;
    product.initialMonthlyPaymentInterestOnly = loanPeriodCalculations[0].interestOnlyInitialMonthlyRepayments;
    product.monthlyPaymentRevertInterestOnly =
      loanPeriodCalculations[loanPeriodCalculations.length - 1].interestOnlyInitialMonthlyRepayments;
    product.trueCost = displayTrueCost;
    product.trueCostInitialPeriod = calculationResult.isForTerm ?
      null : Math.max(trueCostInitial ?? 0, 0);
    product.trueCostCustomPeriod = Math.max(trueCostCustom ?? 0, 0);
    product.aprc = +(aprc * 100).toFixed(1);
    product.totalInterestPayable = +totalInterestPayable.toFixed(2);
  }

  private setCalculatedValues(product: Product, calculationResult: CalculationResult) {
    const {
      trueCost, trueCostInitial, trueCostCustom, initialMonthlyPayment, monthlyPaymentRevert } = calculationResult;
    product.initialMonthlyPayment = initialMonthlyPayment;
    product.monthlyPaymentRevert = monthlyPaymentRevert;
    product.trueCost = +trueCost.toFixed(2);
    product.trueCostInitialPeriod = calculationResult.isForTerm ? null : Math.max(trueCostInitial ?? 0, 0);
    product.trueCostCustomPeriod = Math.max(trueCostCustom ?? 0, 0);
    product.outstandingBalance = calculationResult.outstandingBalance;
  }

  private setBridgingCalculatedValues(model: BdgAffordabilityRequest
    | ProductsBridgingModelRequest, product: BridgingProduct, calculationResult: FullCalculationResult) {
    const { loanPeriodCalculations, trueCost, trueCostInitial, aprc, totalInterestPayable } = calculationResult;
    // Retained and Rolled-up products should have a monthly rate of null
    if (model.methodOfRepayment === MethodOfRepayment.Retained
      || model.methodOfRepayment === MethodOfRepayment.RolledUp) {
      product.monthlyRate = null;
    } else {
      const { repaymentInitialMonthlyRepayments, interestOnlyInitialMonthlyRepayments } = loanPeriodCalculations[0];
      product.monthlyRate = repaymentInitialMonthlyRepayments + interestOnlyInitialMonthlyRepayments;
    }

    product.initialMonthlyPaymentInterestOnly = loanPeriodCalculations[0].interestOnlyInitialMonthlyRepayments;
    product.monthlyPaymentRevertInterestOnly =
      loanPeriodCalculations[loanPeriodCalculations.length - 1].interestOnlyInitialMonthlyRepayments;
    product.trueCost = +trueCost.toFixed(2);
    product.trueCostInitialPeriod = trueCostInitial;
    product.aprc = +(aprc * 100).toFixed(1);
    product.totalInterestPayable = +totalInterestPayable.toFixed(2);
  }

  private calculateTotalInterestPayable(monthlyPaymentCalculations: MonthlyCalculation[]) {
    return monthlyPaymentCalculations
      .map((calc) => this.getRepaymentInterestPaid(calc)
        + (calc.interestOnlyLoanOsAtBeginningOfMonth * calc.interestOnlyInterest))
      .reduce((accumulator, calc) => {
        return accumulator + calc;
      });
  }

  private calculateTrueCostInitial(
    loan: Loan,
    noFeeLoanPeriodCalculations: LoanPeriodCalculation[],
    customTrueCostPeriod: number | null,
  ) {
    return loan.loanPeriods.length > 1 && loan.loanPeriods[0].term > 0
      ? +(((noFeeLoanPeriodCalculations[0].repaymentInitialMonthlyRepayments +
        noFeeLoanPeriodCalculations[0].interestOnlyInitialMonthlyRepayments)
        * (customTrueCostPeriod ?? loan.loanPeriods[0].term)) + loan.additionalFees - loan.cashback).toFixed(2)
      : null;
  }

  private calculateTrueCost(monthlyPaymentCalculations: MonthlyCalculation[], loan: Loan): number {
    return monthlyPaymentCalculations.map(calc => {
      return this.getRepaymentInterestPaid(calc) + this.getRepaymentCapitalRepaid(calc)
        + calc.interestOnlyCapitalRepaid + (calc.interestOnlyLoanOsAtBeginningOfMonth * calc.interestOnlyInterest);
    }).reduce((accumulator, calc) => {
      return accumulator + calc;
    }) + loan.additionalFees - loan.cashback;
  }

  private isModelApplicableForCalculation(model: AffordabilityRequest | BtlAffordabilityRequest
    | BdgAffordabilityRequest | ProductsResidentialModelRequest | ProductsBtlModelRequest
    | ProductsBridgingModelRequest): boolean | undefined | 0 {
    return (model.loanAmount && model.repaymentMethod && model.mortgageTermYears && model.mortgageTermYears) &&
        !(model.repaymentMethod === RepaymentMethod.InterestOnlyPartAndPart && !model.interestOnlyAmount);
  }

  private performLoanPeriodCalculations(loan: Loan): LoanPeriodCalculation[] {
    const multiplier = 12;

    const calcs: LoanPeriodCalculation[] = [];
    let previousPeriodCalc: LoanPeriodCalculation | null = null;

    const interestOnlyInitialAmount =
      loan.interestOnlyLoanAmount + (loan.loanType === LoanType.InterestOnly ? loan.loanFees : 0);
    const termYears = loan.term / 12;
    let annuity = 0;

    for (const loanPeriod of loan.loanPeriods) {
      const remainingMonthsInTerm = loan.term - annuity;
      if (remainingMonthsInTerm <= 0) {
        break;
      }

      const prevAnnuity = annuity;
      annuity += Math.max(Math.min(loanPeriod.term, remainingMonthsInTerm), 0);
      const repaymentEarCompoundedMonthly = Math.pow(1 + (loanPeriod.repaymentRate / multiplier), multiplier) - 1;

      const repaymentCapitalOsAtBeginningOfPeriod = !previousPeriodCalc ?
        loan.repaymentLoanAmount + (loan.loanType === LoanType.InterestOnly ? 0 : loan.loanFees) :
        previousPeriodCalc.repaymentInitialMonthlyRepayments * previousPeriodCalc.repaymentCapitalOsAnnuityMonthly * 12;

      const repaymentPaymentAnnuityMonthly = (1 - Math.pow(1 / (1 + repaymentEarCompoundedMonthly),
        termYears - (!previousPeriodCalc ? 0 : (prevAnnuity / 12)))) / loanPeriod.repaymentRate;

      const interestOnlyEarCompoundedMonthly =
        loanPeriod.interestOnlyRate
          ? Math.pow(1 + (loanPeriod.interestOnlyRate / multiplier), multiplier) - 1
          : 0;

      const calculation: LoanPeriodCalculation = {
        repaymentCapitalOsAtBeginningOfPeriod: repaymentCapitalOsAtBeginningOfPeriod,
        repaymentCapitalOsAnnuityMonthly:
          (1 - Math.pow(1 / (1 + repaymentEarCompoundedMonthly), termYears - (annuity / 12))) /
          loanPeriod.repaymentRate,
        repaymentEarCompoundedMonthly: repaymentEarCompoundedMonthly,
        repaymentPaymentAnnuityMonthly: repaymentPaymentAnnuityMonthly,
        repaymentInitialMonthlyRepayments:
         repaymentCapitalOsAtBeginningOfPeriod / (repaymentPaymentAnnuityMonthly * 12),
        interestOnlyEarCompoundedMonthly: interestOnlyEarCompoundedMonthly,
        interestOnlyInitialMonthlyRepayments:
         interestOnlyInitialAmount * (Math.pow(1 + interestOnlyEarCompoundedMonthly, 1 / 12) - 1),
      };
      calcs.push(calculation);
      previousPeriodCalc = calculation;
    }
    return calcs;
  }

  public performMonthlyCalculations(loan: Loan, loanPeriodCalcs: LoanPeriodCalculation[]): MonthlyCalculation[] {
    const monthlyPayments: MonthlyCalculation[] = [];
    let currentPeriodIndex = 0;
    let monthsRemainingInCurrentLoanPeriod = loan.loanPeriods[currentPeriodIndex].term;

    for (let i = 0; i < loan.term; i++) {
      while (monthsRemainingInCurrentLoanPeriod === 0) {
        currentPeriodIndex++;
        if (!loan.loanPeriods[currentPeriodIndex]) {
          return monthlyPayments;
        }
        monthsRemainingInCurrentLoanPeriod = loan.loanPeriods[currentPeriodIndex].term ?? 0;
      }
      const interestOnlyLoanOsAtBeginningOfMonth = loan.interestOnlyLoanAmount +
        (loan.loanType === LoanType.InterestOnly ? loan.loanFees : 0);

      const payment: MonthlyCalculation = {
        loanPeriodIndex: currentPeriodIndex,
        repaymentLoanOsAtBeginningOfMonth: i === 0
          ? loan.repaymentLoanAmount + (loan.loanType === LoanType.InterestOnly ? 0 : loan.loanFees)
          : monthlyPayments[i - 1].repaymentLoanOsAtBeginningOfMonth
            - this.getRepaymentCapitalRepaid(monthlyPayments[i - 1]),
        repaymentMonthlyEffInterest:
          Math.pow(1 + loanPeriodCalcs[currentPeriodIndex]?.repaymentEarCompoundedMonthly, 1 / 12) - 1,
        repaymentTotalRepayment: loanPeriodCalcs[currentPeriodIndex]?.repaymentInitialMonthlyRepayments ?? 0,
        interestOnlyLoanOsAtBeginningOfMonth: interestOnlyLoanOsAtBeginningOfMonth,
        interestOnlyInterest:
          Math.pow(1 + loanPeriodCalcs[currentPeriodIndex]?.interestOnlyEarCompoundedMonthly, 1 / 12) - 1,
        interestOnlyCapitalRepaid: i === loan.term - 1 ? interestOnlyLoanOsAtBeginningOfMonth : 0,
      };
      monthlyPayments.push(payment);
      monthsRemainingInCurrentLoanPeriod--;
    }
    return monthlyPayments;
  }

  private getRepaymentCapitalRepaid(monthlyCalculation: MonthlyCalculation): number {
    return monthlyCalculation.repaymentTotalRepayment - this.getRepaymentInterestPaid(monthlyCalculation);
  }

  private getRepaymentInterestPaid(monthlyCalculation: MonthlyCalculation): number {
    return monthlyCalculation.repaymentLoanOsAtBeginningOfMonth * monthlyCalculation.repaymentMonthlyEffInterest;
  }

  private toResult(
    values: Omit<FullCalculationResult, 'initialMonthlyPayment' | 'monthlyPaymentRevert'>,
  ): FullCalculationResult {
    return {
      ...values,
      get initialMonthlyPayment() {
        return this.loanPeriodCalculations[0].interestOnlyInitialMonthlyRepayments
          + this.loanPeriodCalculations[0].repaymentInitialMonthlyRepayments;
      },
      get monthlyPaymentRevert() {
        return this.loanPeriodCalculations[this.loanPeriodCalculations.length - 1].interestOnlyInitialMonthlyRepayments
          + this.loanPeriodCalculations[this.loanPeriodCalculations.length - 1].repaymentInitialMonthlyRepayments;
      },
    };
  }
}
