/* eslint-disable camelcase */
import { Injectable } from '@angular/core';
import { BridgingProduct, MortgageType, Product } from 'apps/shared/src/models';
import { ProductsSearchService } from 'apps/clubhub/src/app/ignite/services';
import { CustomFee } from '@msslib/models';
import { feesDisplayNames } from '@msslib/constants/product-fees';

@Injectable({
  providedIn: 'root',
})
export class ProductsUtils {
  public readonly savingProductsCount = 50;

  public getProductDescription(product: Product | BridgingProduct): string {
    return (product as Product).productDescription ?? (product as BridgingProduct).description;
  }

  public getProductCode(product: Product | BridgingProduct): string {
    return (product as Product).productCode ?? (product as BridgingProduct).code;
  }

  public getProductTrueCost(product: Product | BridgingProduct): number | null | undefined {
    return this.isBridgingProduct(product) ? (product as BridgingProduct).trueCost : (product as Product).trueCost;
  }

  private isBridgingProduct(product: Product | BridgingProduct): boolean {
    return 'isRegulated' in product && 'bridgeToLet' in product;
  }

  public calculateTotalFees(product: Product, productsSearchService: ProductsSearchService): number {
    return product.totalFees + Math.max(this.getAssumedLegalFee(product, productsSearchService) ?? 0, 0) +
      this.getTotalCustomFeeAmount(productsSearchService);
  }

  public getProductsFees(product: Product, productsSearchService: ProductsSearchService) {
    const customFees = productsSearchService.customProductCalculations?.customFees || [];

    const productFees = Object.keys(product.fees).map(key => {
      return {
        name: feesDisplayNames[key],
        value: product.fees[key],
      };
    });

    customFees.forEach(customFee => {
      productFees.push({
        name: customFee.feeName,
        value: this.getCustomFeeAmount(customFee, productsSearchService),
      });
    });

    const assumedFeeAmount = this.getAssumedLegalFee(product, productsSearchService);
    if (assumedFeeAmount) {
      productFees.push({
        name: 'Assumed Legal Fee',
        value: assumedFeeAmount,
      });
    }

    return productFees;
  }

  public getAssumedLegalFee(product: Product, productsSearchService: ProductsSearchService): number | null {
    let feeAmount = productsSearchService.customProductCalculations?.assumedLegalFeeCost;
    if (!feeAmount) {
      return 0;
    }

    if (feeAmount < 2) {
      const loanAmount = productsSearchService.getLoanAmount();
      feeAmount = (loanAmount ?? 0) * feeAmount;
    }
    return product.mortgageType?.includes(MortgageType.Remortgage) && !product.freeLegal ? (feeAmount ?? 0) : 0;
  }

  public getCustomFeeAmount(fee: CustomFee, productsSearchService: ProductsSearchService): number {
    if (fee.feeAmount > 2) {
      return fee.feeAmount;
    }
    return fee.feeAmount * (productsSearchService.getLoanAmount() ?? 0);
  }

  public getTotalCustomFeeAmount(productsSearchService: ProductsSearchService): number {
    const customFees = productsSearchService.customProductCalculations?.customFees || [];
    return customFees
      .map(fee => this.getCustomFeeAmount(fee, productsSearchService))
      .reduce((partialSum, fee) => partialSum + fee, 0);
  }

  public getRevertRateColumnValue(product: Product) {
    if (!product) {
      return null;
    }

    return !!product.forTerm ? null : product.revertRate;
  }
}
