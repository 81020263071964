import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, UntypedFormGroup, ValidationErrors,
  Validators } from '@angular/forms';
import { scrollIntoView } from '@msslib/helpers/dom-helpers';
import { LendingTypeService } from '@msslib/services/lending-type.service';
import { ModalStateService } from '@msslib/services/modal-state.service';
import { ModalService } from '@msslib/services/modal.service';
import { ToastService } from '@msslib/services/toast.service';
import { Fee, TariffOfCharges } from 'apps/usermanager/src/app/models/tariff-of-charges';
import { TariffOfChargesService } from 'apps/usermanager/src/app/services/tariff-of-charges.service';
import { Subject, debounceTime, distinctUntilChanged, pairwise, startWith, takeUntil  } from 'rxjs';

type FormControlPath = Parameters<UntypedFormGroup['get']>[0];

@Component({
  selector: 'lib-lender-tariff-of-charges',
  templateUrl: './lender-tariff-of-charges.component.html',
  styleUrls: ['./lender-tariff-of-charges.component.css'],
})
export class LenderTariffOfChargesComponent implements OnInit, OnDestroy {
  @Input({ required: true }) public lenderNameId: number;
  @Input() public disabled = false;
  public form: FormGroup;
  public feeList: {
    name: string;
    section: 'Before the first monthly payment' | 'During the mortgage term' | 'Ending the mortgage term';
    description: string;
  }[] = [
      {
        name: 'CHAPS Fee',
        section:'Before the first monthly payment',
        description: 'This covers the cost of transferring funds outside the bank/building society.',
      },
      {
        name: 'Conveyancing Fee',
        section:'Before the first monthly payment',
        description: `This is the legal fee that you want included in your ESIS document to confirm the estimated costs
          involved by the client to their solicitor, if applicable, for the work on your behalf.
          These fees are normally changed by the solicitor directly to the client`,
      },
      {
        name: 'Additional Borrowing Fee',
        section:'During the mortgage term',
        description: '<strong>Also known as Further Advance Fee.</strong>',
      },
      {
        name: 'Mortgage Exit Fee',
        section:'Ending the mortgage term',
        description: `
          <p><strong>Also known as a Mortgage Completion Fee, or Exit Administration Fee.</strong><p>
          Charged for closing a mortgage account. Payable either at the end of the mortgage term or before the end
          of the mortgage term if loan is transferred to another lender or another property.`},
      {
        name: 'Deeds Release Fee',
        section:'Ending the mortgage term',
        description: `Included only when chargeable at closure of a mortgage account.
          Payable ether at the end of the mortgage term or before the end of the mortgage term if the loan
          is transferred to another lender or property.`,
      },
    ];

  private ngUnsubscribe = new Subject<void>();
  @ViewChild('lendingTypeModalTemplateRef', { static: true })
  public lendingTypeModalTemplateRef: TemplateRef<void>;
  public resiId: number | null;
  public btlId: number | null;
  public sections:string[] =
    ['Before the first monthly payment', 'During the mortgage term', 'Ending the mortgage term'];

  public model: TariffOfCharges | null;
  @Output() public unsavedChangesChange = new EventEmitter<boolean>();

  public constructor(
    private formBuilder: FormBuilder,
    private tariffOfChargesService: TariffOfChargesService,
    private toastService: ToastService,
    public modalService: ModalService,
    private modalStateService: ModalStateService,
    public lendingTypeService: LendingTypeService) { }

  public ngOnInit(): void {
    this.resiId = this.lendingTypeService.residentialId;
    this.btlId = this.lendingTypeService.buyToLetId;
    this.createFeeForm(null);
    this.tariffOfChargesService.get(this.lenderNameId).subscribe((res: TariffOfCharges)=> this.createFeeForm(res));
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get feeArray() {
    return this.form.controls['fees'] as FormArray;
  }

  private createFeeForm(model: TariffOfCharges | null) {
    this.model = model;
    this.form = this.formBuilder.group({fees: this.formBuilder.array([], [this.uniqueFeeValidator.bind(this)])});
    this.feeList.forEach(fee =>{
      const existingFees = model?.fees?.filter(f =>f.name === fee.name);
      existingFees?.forEach(existingFee =>{
        const feeGroup = this.createFee({...fee, ...existingFee});
        this.feeArray.push(feeGroup);
      });
      if (existingFees?.length === 0) {
        const feeGroup = this.createFee({...fee});
        this.feeArray.push(feeGroup);
      }
    });

    if (this.disabled) {
      this.form.disable({ onlySelf: false });
    }
    this.setIsDuplicated();
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.unsavedChangesChange.emit(true));
  }

  private createFee(data:any): FormGroup {
    const group =  this.formBuilder.group({
      name: (data.name),
      description: (data.description),
      amount: [data?.amount ?? 0, Validators.max(99999.99)],
      lendingTypeId: [data?.lendingTypeId ?? null],
      feeConfiguration: this.formBuilder.group({
        purchase: data?.feeConfiguration?.purchase ?? false,
        remortgage: data?.feeConfiguration?.remortgage ?? false,
        productTransfer: data?.feeConfiguration?.productTransfer ?? false,
        furtherAdvance: data?.feeConfiguration?.furtherAdvance ?? false,
        existingCustomer: data?.feeConfiguration?.existingCustomer ?? false,
        secondCharge: data?.feeConfiguration?.secondCharge ?? false,
      }),
      duplicated: false,
      section: data.section,
    },
    { validators: [this.checkFeeConfiguration.bind(this)],
    });

    group.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      startWith(group.value),
      pairwise())
      .subscribe(([prev, next]) => {
      //the modal should only appear if the original value is 'All' (null).
        if (prev.lendingTypeId === null && prev.lendingTypeId !== next.lendingTypeId) {
          this.openLendingTypeModal(next, this.feeArray.value.findIndex(x=>x.name === prev.name));
        }
      });
    return group;
  }

  // Custom validator to ensure at least one option is selected in fee configuration
  private checkFeeConfiguration(group: FormGroup): ValidationErrors | null {
    const amount = group?.controls['amount'].value;
    if (!+amount) {
      return null;
    }

    const feeConfigGroup = group.controls['feeConfiguration'] as FormGroup;
    const purchase = feeConfigGroup.controls['purchase'].value;
    const remortgage = feeConfigGroup.controls['remortgage'].value;
    const productTransfer = feeConfigGroup.controls['productTransfer'].value;
    const furtherAdvance = feeConfigGroup.controls['furtherAdvance'].value;
    const existingCustomer = feeConfigGroup.controls['existingCustomer'].value;
    const secondCharge = feeConfigGroup.controls['secondCharge'].value;
    return purchase || remortgage || productTransfer || furtherAdvance || existingCustomer || secondCharge
      ? null : { invalidFeeConfiguration: true };
  }

  private uniqueFeeValidator(feeArray: FormArray): ValidationErrors | null {
    const feeNames = new Set(feeArray.value.map(x=>x.name));

    for (const feeName of feeNames) {
      const duplicates = feeArray.value.filter(fee => fee.name === feeName);

      for (const lendingType of [1,2]) {
        const lendingTypeGroup = duplicates?.filter(x=>x.lendingTypeId === null || x.lendingTypeId === lendingType);
        if (lendingTypeGroup.length < 2) {
          continue;
        }
        for (let i = 0; i < lendingTypeGroup.length; i++) {
          for (let j = i + 1; j < lendingTypeGroup.length; j++) {
            if ((lendingTypeGroup[i].feeConfiguration.purchase && lendingTypeGroup[j].feeConfiguration.purchase) ||
              (lendingTypeGroup[i].feeConfiguration.remortgage && lendingTypeGroup[j].feeConfiguration.remortgage)
            ) {
              return { duplicateConfiguration: {name: feeName, lendingTypeId: [null, lendingType] }};
            }
          }
        }
      }
    }
    return null;
  }

  public onSubmit() {
    if (this.disabled) {
      return;
    }

    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.feeArray.updateValueAndValidity();
    if (this.form.valid) {
      const formFees = this.form.value.fees.map(x=> {
        return {
          amount: x.amount,
          feeConfiguration: x.feeConfiguration,
          name: x.name,
          lendingTypeId: x.lendingTypeId,
        } as Fee;
      });
      const fees = formFees.concat(this.model?.fees
        ?.filter(x=>['Disbursement Fee', 'Higher Lending Fee', 'Processing Fee'].includes(x.name)));
      const request = {...this.form.value};
      request.fees = fees;
      this.tariffOfChargesService.save(this.lenderNameId, request)
        .subscribe(()=> {
          this.toastService.success('Tariff successfully updated');
          this.unsavedChangesChange.emit(false);
        });
    } else {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      scrollIntoView('.is-invalid');
    }
  }

  public invalid(path: FormControlPath) {
    const ctrl = this.form.get(path);
    return ctrl?.invalid && ctrl.touched;
  }

  public errors(path: FormControlPath) {
    return this.form.get(path)?.errors;
  }

  public openLendingTypeModal(fee: any, index: number) {
    return this.modalService.open<never>({
      title: 'Apply Separate Fee Dependent On Lending Type',
      template: this.lendingTypeModalTemplateRef,
      size: 'md',
      data: {...{feeForm:fee}, ...{index: index}},
    }).then(() => null, () => null);
  }

  public duplicateFee() {
    const fee = this.modalStateService.options.data.feeForm;
    const index = this.modalStateService.options.data.index;
    const newFee = {...fee};
    newFee.lendingTypeId = fee.lendingTypeId === this.resiId ? this.btlId : this.resiId;
    this.addAdditionalLine(newFee, index);
  }

  public setDefaultLendingType() {
    this.feeArray.at(this.modalStateService.options.data.index).patchValue({lendingTypeId: null});
  }

  public addAdditionalLine(fee: Fee, index: number):void {
    this.feeArray.insert(index + 1, this.createFee(fee));
    if (this.doesFeeNameExists(fee.name)) {
      this.feeArray.at(index + 1).get('duplicated')?.setValue(true);
    }
  }

  private setIsDuplicated() {
    const nameCount: { [key: string]: number } = {};

    for (let i = 0; i < this.feeArray.length; i++) {
      const feeName = this.feeArray.at(i).value.name;

      if (nameCount[feeName]) {
        nameCount[feeName]++;
      } else {
        nameCount[feeName] = 1;
      }

      if (nameCount[feeName] > 1) {
        this.feeArray.at(i).get('duplicated')?.setValue(true);
      } else {
        this.feeArray.at(i).get('duplicated')?.setValue(false);
      }
    }
  }

  public removeAdditionalLine(index: number):void {
    this.feeArray.removeAt(index);
  }

  private doesFeeNameExists(newFeeName: string): boolean {
    for (let i = 0; i < this.feeArray.length; i++) {
      const feeName = this.feeArray.at(i).value.name;
      if (feeName === newFeeName) {
        return true;
      }
    }
    return false;
  }

  public checkDuplicationError(i:number) {
    return this.errors(['fees'])?.duplicateConfiguration?.name === this.feeArray.controls[i].value.name &&
      this.errors(['fees'])?.duplicateConfiguration?.lendingTypeId
        .includes(this.feeArray.controls[i].value.lendingTypeId);
  }
}
