export enum QuestionType {
  FullRange = 0,
  MultiSelect = 1,
  SingleSelect = 2,
}

export enum LenderInputType {
  Range = 0,
  Min = 1,
  Max = 2,
}

export enum CriteriaOutcome {
  WillLend = 1,
  Refer = 2,
}

export interface AddCriteriaIssue {
  text: string;
  description: string;
  lendingTypeIds: number[];
}

export interface EditCriteriaIssue {
  criteriaIssueId: number;
  text: string;
  description: string;
}

export interface AnswerChoice {
  id: number;
  text: string;
}

export interface ContextQuestion {
  id: number;
  text: string;
  ordinal: number;

  answerType: QuestionType;
  answerUnits: string | null;
  answerRangeLower: number | null;
  answerRangeUpper: number | null;
  answerChoices: AnswerChoice[] | null;
}

export interface CriteriaContext {
  id: number;
  criteriaContextLendingTypeId: number;
  criteriaContextVersionId: number;
  name: string;
  description: string;
  isLive?: boolean;
  lenderVisible?: boolean;
  brokerVisible?: boolean;
  lenderLive?: boolean;
  questions: ContextQuestion[];
}

export interface CriteriaIssue {
  id: number;
  name: string;
  description: string;
  lendingTypeId: number;
  criteriaIssueLendingTypeId: number;
  contexts: CriteriaContext[];
  verifiedPercent?: number;
}

export interface AnswerDefinition {
  type: QuestionType;
  units: string;
  upper: number;
  lower: number;
  choices: any[];
  lenderInputType?: LenderInputType;
}

export interface QuestionDefinition {
  text: string;
  units: string;
  upper: number;
  lower: number;
  choices: unknown[];
}

export interface ExampleQuestionDefinition {
  text: string;
  units: string;
  from: number;
  to: number;
  choices: any[];
}

export interface CriteriaExampleValues {
  outcomeResult: CriteriaOutcome;
  exampleValues: ExampleQuestionDefinition[];
}

export interface CriteriaQuestions {
  answerDefinition: AnswerDefinition;
  id: number;
  ordinal: number;
  text: string;
}

export interface AnswerValue {
  choices: any[];
  from: string;
  questionId: number;
  to: number;
  answerType: QuestionType;
}

export interface CriteriaAnswers {
  outcomeResult: CriteriaOutcome;
  answerValues: AnswerValue[];
  name: string;
  criteriaId?: number;
}

export interface ContextQuestions {
  criteriaExampleValues: CriteriaExampleValues[];
  criteriaQuestions: CriteriaQuestions[];
  criteriaAnswers: CriteriaAnswers[];
  contextTitle: string;
  isLive: boolean;
  isVerified: boolean;
}
