import { CaseData, FloodRiskData, IgniteResponse, SsoCallBackEventType } from 'apps/clubhub/src/app/ignite/models';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import { BehaviorSubject, Observable, Subject, combineLatest, firstValueFrom, forkJoin, of } from 'rxjs';
import { v4 as newGuid } from 'uuid';
import orderBy from 'lodash-es/orderBy';
import { map, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import {
  AffordabilityCallbackRequestData,
  AffordabilityResult,
  CreateLenderAuditLogRequest,
  CriteriaResponse,
  CriteriaType,
  IgnitePlusAffordabilityCallbackRequest,
  IgnitePlusAffordabilityResult,
  LenderAuditResult,
  LenderAuditType,
  LendersAbilityResponse,
  LendingTypeCode,
  MatchedLenderProducts,
  OutcomeDetails,
  OutcomeDetailsCard,
  OutcomeHistoryDetailsCard,
  OutcomeResponse,
  OutcomeResponseContextResult,
  ProductsBdgModelRequest,
  ProductsBtlModelRequest,
  ProductsResidentialModelRequest,
  PropertyOutcomeDetailsDetailed,
  RepaymentMethod,
  repaymentMethodToString,
} from 'apps/shared/src/models';
import { CriteriaService } from './criteria.service';

import { ISortOption } from '@msslib/models/filter-sort';
import {
  AnalyticsService,
  AuthorizeService,
  ClubHubDataService,
  ConfigService,
  FormStateService,
  IgniteHelperService,
  LendingTypeService,
  PageNavMessageService,
  UtilsService,
} from '@msslib/services';
import { roles } from '@msslib/constants';
import { appName } from '@msslib/constants/app-name';
import {
  AvailableLenderToolsCategories,
  LenderTools,
  MiSaveOutcomeModel,
  OutcomeResults,
} from '@msslib/models';
import {
  AffordabilityAndUiRequest,
  AffordabilityLenders,
  AffordabilityPollResponse,
  AffordabilityRequest,
  AffordabilityServiceLoadingViewMode,
  AnswerType,
  AuditResultsStatus,
  BdgAffordabilityRequest,
  BtlAffordabilityRequest,
  IApplicantOutcomeIds,
  IBaseIncomeContext,
  IIncomeAndExpenditure,
  IPropertyAndLoan,
  IPropertyBtl,
  IUiAffordabilityModel,
  IUiApplicant,
  IUiApplicantAge,
  IUiBaseIncome,
  IUiCriteria,
  IUiCriteriaQuestion,
  IUiMainIncome,
  IUiOtherMortgage,
  MainIncome,
  MaxAffordabilityLoanMessage,
  ResultsView,
  StepName,
  UiAdditionalIncome,
  benefitIncomeCriteria,
  otherIncomeCriteria,
  workRelatedIncomeCriteria,
} from 'apps/clubhub/src/app/ignite/models/affordability';
import {
  mockAffordabilityRequest,
  mockAffordabilityResponse,
} from 'apps/clubhub/src/app/ignite/testing/affordability';
import { IBasketItem, IContext } from 'apps/clubhub/src/app/models';
import { ModalService } from '@msslib/services/modal.service';
import { BasketService } from 'apps/clubhub/src/app/services';
import { Router } from '@angular/router';
import { RioService } from './rio.service';
import { PropertyModelRequest } from 'apps/clubhub/src/app/ignite/models/property';
import isEmpty from 'lodash-es/isEmpty';
import { LenderAuditService } from 'apps/shared/src/services';
import { ProductsFilterService } from './products-filter.service';
import { ProductsSearchService } from './products-search.service';
import { IgniteCommonDataService } from './ignite-common-data.service';
import { CaseLinkModalComponent } from 'apps/clubhub/src/app/ignite/components/case-link-modal';
import { NavMessageKeys } from '@msslib/models/nav';
import { CriteriaV2OutcomeService } from '../pages/criteria-v2/services';
import { IntuitiveCriteriaPropertyStatus } from '../pages/criteria-v2/models';
import { OutcomeHistoryResponse } from '../models/outcome-history';
import { CriteriaSwitcherService } from 'apps/clubhub/src/app/ignite/services/criteria-switcher.service';
import { igniteSortOptions, outcomeList, outcomeSortOrder, productsMatched } from '../constants/ignite';

@Injectable({
  providedIn: 'root',
})
export class IgniteService {
  public activeStepIndex: number;
  public additionalOutcomeIds: (number | undefined)[] = [];
  public additionalOutcomes: OutcomeResponse[] = [];
  public affordabilityModelRequest: AffordabilityRequest | BtlAffordabilityRequest
    | BdgAffordabilityRequest | null = null;
  public auditResultsStatus: AuditResultsStatus = AuditResultsStatus.NotSubmitted;
  public autoAddedPropertyOutcomeIds: number[] = [];
  public btlModalBeenOpened = false;
  public caseEditedAll = false;
  public caseReran = false;
  public criteriaLoading = false;
  public currentStepName: StepName;
  public drawerOpen = false;
  public form = new UntypedFormGroup({});
  public intuitiveContextCards: OutcomeDetailsCard[] = [];
  public intuitiveOutcomes: OutcomeResponse[] = [];
  public isTopSlicing = false;
  public lastSavedAffordabilitySearchId: string | null;
  public lenderCount?: number | null = null;
  public lenders: AffordabilityLenders[] | undefined = [];
  public loading$ = new BehaviorSubject(AffordabilityServiceLoadingViewMode.None);
  public matchedLenderProducts: MatchedLenderProducts[] = [];
  public miSaveOutcomeModel: MiSaveOutcomeModel | null;
  private mockAffordability: boolean;
  public model: IUiAffordabilityModel | undefined;
  public options = {} as FormlyFormOptions;
  public previousLendingTypeCode: LendingTypeCode;
  public productLendersList: string[] = [];
  public productsLoading = false;
  public progress = 0;
  private propertyIntuitiveOutcomes: OutcomeResponse[] = [];
  public propertyOutcomeIds: number[] = [];
  public propertyOutcomes: OutcomeResponse[] = [];
  public criteriaSearchId: string | null;
  public propertySearchId: string | null;
  public propertySearchRequestViewModel: PropertyModelRequest | null = null;
  public resultsView: ResultsView = ResultsView.Details;
  public scheduled = false;
  private showExpenditureBtl = false;
  public skipListChange = new Subject<string[]>();
  public outcomeList: ISortOption[] = outcomeList;
  public igniteSortOptions: ISortOption[] = igniteSortOptions;
  public productsMatched: ISortOption[] = productsMatched;
  public floodRiskData: FloodRiskData | null = null;
  public allProductsLoading$ = new BehaviorSubject(false);
  public igniteResponseLoading$ = new BehaviorSubject<boolean>(false);
  public igniteResponseReload$ = new BehaviorSubject<boolean>(false);
  public preservePropertyCriteria = false;
  public fixedInitialPeriod: number | undefined;
  public currentViewSaved = false;
  public advertBannerVisible = true;
  public hasPresetPropertyValue: boolean;
  public s365Data: CaseData | null = null;
  public printSortOption: unknown;
  public historicalCriteriaVersion: number;
  public savedReport: boolean;

  public constructor(
    @Inject(DOCUMENT) private document: Document,
    private helperService: IgniteHelperService,
    private clubHubDataService: ClubHubDataService,
    private configService: ConfigService,
    private authService: AuthorizeService,
    private modalService: ModalService,
    private utilService: UtilsService,
    private lenderAuditService: LenderAuditService,
    private lendingTypeService: LendingTypeService,
    @Inject(BasketService) private basketService,
    private formStateService: FormStateService,
    private criteriaService: CriteriaService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private rioService: RioService,
    private productFilterService: ProductsFilterService,
    private productsSearchService: ProductsSearchService,
    private igniteCommonDataService: IgniteCommonDataService,
    private pageNavMessageService: PageNavMessageService,
    private criteriaV2OutcomeService: CriteriaV2OutcomeService,
    private criteriaSwitcherService: CriteriaSwitcherService,
  ) {
    this.getAvailableLenders();
    this.reset();

    // MCONE-6289 - fix product criteria modal not showing property criteria added to a products search
    criteriaV2OutcomeService.outcomeUpdated?.subscribe((outcome) => {
      this.splitOutPropertyCriteria(null, outcome.details);
    });
  }

  public get resultsLoading(): Observable<boolean> {
    return combineLatest([
      // affordability and property loading
      this.loading$,
      this.criteriaService.loading$,
      this.criteriaService.criteriaLoading$,
      this.productsSearchService.anyProductsLoading,
    ]).pipe(map(([affordabilityLoading, criteriaLoading1, criteriaLoading2, productsLoading]) =>
      affordabilityLoading !== AffordabilityServiceLoadingViewMode.None ||
      criteriaLoading1 || criteriaLoading2 || productsLoading,
    ));
  }

  public get loading() {
    return this.loading$.value;
  }

  public set loading(value: AffordabilityServiceLoadingViewMode) {
    this.loading$.next(value);
  }

  public get criteriaV2Enabled(): boolean {
    return this.criteriaSwitcherService.criteriaV2Enabled;
  }

  public get isInaccuracyOrBrokerEmail() {
    return !this.savedReport && this.authService.historyView;
  }

  public get productsIntuitiveCriteriaId(): string | null {
    return this.productsSearchService.productsIntuitiveCriteriaId;
  }

  public get productsSearchLenders() {
    return this.productsSearchService.matchedLenders;
  }

  public get propertyOnly(): boolean {
    return this.router.url.includes('property') && !this.router.url.includes('affordability');
  }

  public get criteriaOnly(): boolean {
    return this.router.url.includes('criteria') && !this.router.url.includes('affordability');
  }

  public get affordabilityOnly(): boolean {
    return this.router.url.includes('affordability');
  }

  public get restoredCriteriaOnly(): boolean {
    return this.router.url.includes('restoreCriteria');
  }

  public get productsOnly(): boolean {
    return this.igniteCommonDataService.productsOnly;
  }

  public get isSecondChargeProducts(): boolean {
    return this.productsSearchService.isSecondChargeProducts;
  }

  public get isProductView(): boolean {
    return this.isMatchedProductsView ||
          this.isViewAllProductsView ||
          this.isComparedProductsView;
  }

  public get lendingTypeCode() {
    if (this.previousLendingTypeCode !== this.lendingTypeService.lendingType?.code as LendingTypeCode) {
      this.previousLendingTypeCode = this.lendingTypeService.lendingType?.code as LendingTypeCode;
      this.getAvailableLenders();
    }
    return this.lendingTypeService.lendingType?.code as LendingTypeCode;
  }

  public get lendingTypeId() {
    return this.lendingTypeService.lendingType?.id ?? -1;
  }

  public get lendingTypeName() {
    return this.lendingTypeService.lendingType?.name ?? '';
  }

  private set expenditureBtlShow(show: boolean) {
    this.showExpenditureBtl = show;
  }

  public get expenditureBtlShow() {
    return this.showExpenditureBtl;
  }

  public set topSlicingChange(topSlicing: boolean) {
    this.isTopSlicing = topSlicing;
  }

  public get topSlicingChange() {
    return this.isTopSlicing;
  }

  public get hasAdditionalCriteria() {
    return this.additionalOutcomes.length > 0 || this.criteriaV2OutcomeService.basketItems.length > 0;
  }

  public get hasNonAutoAddedCriteria() {
    if (this.criteriaV2Enabled) {
      return this.criteriaV2OutcomeService.basketItems
        .filter(item => item.criteriaType === CriteriaType.Manual).length > 0;
    }
    return this.additionalOutcomes.filter(x => x.contextResults.find(y => !y.autoAdded)).length > 0;
  }

  public get hasAdditionalProperty() {
    return this.propertyOutcomes.length > 0;
  }

  public get hasAnyCriteria() : boolean {
    if (this.criteriaV2Enabled) {
      return !!this.criteriaV2OutcomeService.basketItems.length;
    }
    return !!this.criteriaService.outcomeDetails?.contexts?.length;
  }

  public get affordabilityLendersList() {
    return this.igniteCommonDataService.affordabilityLendersList;
  }

  public addApplicant() {
    this.model?.applicants.applicantAges?.push(this.initialApplicantAge);
    this.model?.applicants.applicantAges?.forEach((applicant, index) => (applicant.applicantNumber = index + 1));
  }

  public addApplicantIncome() {
    if (this.model !== undefined && this.model?.incomeAndExpenditure === undefined) {
      const numOfApplicants = this.model?.applicants.applicantAges?.length ?? 0;

      for (let i = 0; i <= numOfApplicants; i++) {
        const applicant = [] as IUiApplicant[];
        applicant.push({
          applicantNumber: i + 1,
        } as IUiApplicant);

        this.model.incomeAndExpenditure = {
          applicants: applicant,
        } as unknown as IIncomeAndExpenditure;
      }
    }

    this.model?.incomeAndExpenditure?.applicants?.push(this.initialApplicant);
    this.model?.incomeAndExpenditure.applicants?.forEach((applicant, index) =>
      (applicant.applicantNumber = index + 1));
  }

  public addBtlProperty() {
    this.model?.otherProperties.propertiesBtl.push(this.initialPropertyBtl);
    this.model?.otherProperties.propertiesBtl.forEach((applicant, index) => (applicant.propertyNumber = index + 1));
  }

  public addResiProperty() {
    this.model?.otherProperties.properties.push(this.initialPropertyResi);
    this.model?.otherProperties.properties.forEach((applicant, index) => (applicant.propertyNumber = index + 1));
  }

  public updateModel(model = {}) {
    if (this.model) {
      this.model = { ...this.model, ...model };
    }
  }

  public resetResults() {
    this.progress = 0;
    this.lenderCount = null;
    this.igniteCommonDataService.results = null;
    this.intuitiveContextCards = [];
    this.intuitiveOutcomes = [];
    this.additionalOutcomes = [];
    this.propertyOutcomes = [];
    this.propertyIntuitiveOutcomes = [];
    this.miSaveOutcomeModel = null;
    this.matchedLenderProducts = [];

    if (!this.preservePropertyCriteria && !this.propertyOnly) {
      this.propertyOutcomeIds = [];
      this.autoAddedPropertyOutcomeIds = [];
    }
    this.preservePropertyCriteria = false;
    this.productsSearchService.isOutcomeResults = false;

    if (this.criteriaV2Enabled) {
      this.criteriaV2OutcomeService.reset();
    } else {
      this.criteriaService.resetParamsToDefault();
    }
  }

  public resetParamsToDefault(): void {
    this.productsSearchService.resetParamsToDefault();
    this.productsSearchService.reset();
    this.igniteCommonDataService.affordabilityModel = null;
    this.activeStepIndex = 0;
    this.additionalOutcomeIds = [];
    this.affordabilityModelRequest = null;
    this.auditResultsStatus = AuditResultsStatus.NotSubmitted;
    this.btlModalBeenOpened = false;
    this.caseEditedAll = false;
    this.caseReran = false;
    this.criteriaLoading = false;
    this.drawerOpen = false;
    this.isTopSlicing = false;
    this.lastSavedAffordabilitySearchId = '';
    this.lenders = [];
    this.loading = AffordabilityServiceLoadingViewMode.None;
    this.productsLoading = false;
    this.progress = 0;
    this.criteriaSearchId = null;
    this.propertySearchId = null;
    this.propertySearchRequestViewModel = null;
    this.resultsView = ResultsView.Details;
    this.scheduled = false;
    this.showExpenditureBtl = false;
    this.skipListChange = new Subject<string[]>();
    this.floodRiskData = null;
    this.allProductsLoading$ = new BehaviorSubject(false);
    this.igniteResponseReload$ = new BehaviorSubject(false);
    this.igniteResponseLoading$ = new BehaviorSubject(false);
    this.fixedInitialPeriod = 0;
    this.currentViewSaved = false;
    this.advertBannerVisible = true;
    this.hasPresetPropertyValue = false;
    this.intuitiveOutcomes = [];
    this.additionalOutcomes = [];
    this.propertyOutcomes = [];
    this.propertyIntuitiveOutcomes = [];
    this.miSaveOutcomeModel = null;
    if (!this.preservePropertyCriteria && !this.propertyOnly) {
      this.propertyOutcomeIds = [];
      this.autoAddedPropertyOutcomeIds = [];
    }
  }

  public setOutcomeResult(isOutcomeResult) {
    this.productsSearchService.isOutcomeResults = isOutcomeResult;
  }

  public getAvailableLenders() {
    this.getAvailableLendersResponse().subscribe(lendersAbility => {
      this.igniteCommonDataService.affordabilityLendersList = lendersAbility.affordabilityLenders;
      this.productLendersList = lendersAbility.productLenders;
    });
  }

  public getAvailableLendersResponse() {
    return this.clubHubDataService
      .get<LendersAbilityResponse>(
        `lender/availableLenders?lendingTypeCode=${this.lendingTypeService.lendingType?.code ?? 'RES'}`,
      );
  }

  private updateCriteriaBasket(contextCards: OutcomeHistoryDetailsCard[], autoAdded = false) {
    contextCards.forEach(o => {
      if (!this.basketService.basketItems.find((x: IBasketItem) => x.issue.id === o.issue.id)) {
        this.basketService.addBasketItem(o.issue);
      }
      const propertyIssue = this.basketService.basketItems.find((x: IBasketItem) => x.issue.id === o.issue.id);
      if (!propertyIssue.contexts.find((x: IContext) => x.context.id === o.context.id)) {
        this.basketService.addQuestionAndAnswerMulti(
          o.issue.id,
          o.context,
          o.questionsAndAnswers,
          autoAdded ? autoAdded : o.autoAdded,
        );
      }
    });
  }

  public reset(resultModel: AffordabilityResult | null = null) {
    this.form.markAsUntouched();
    this.resetResults();
    this.currentViewSaved = false;
    this.affordabilityModelRequest = null;
    this.authService.historyView = false;
    this.resetOptions();
    this.savedReport = false;
    this.igniteCommonDataService.searchToolsInUse = {
      affordability: false,
      products: false,
      property: false,
      criteria: false,
    };
    this.model = this.initialModel();
    this.rioService.initializeRioForm();
    this.form = new UntypedFormGroup({});
    this.options = {};
    if (this.configService.isLocal) {
      // this.mockAffordability = false;
      this.model = mockAffordabilityRequest;
      // this.model = MockRioAffordabilityRequestForTwoApplicants;
      // this.setupMockResponse(LendingTypeCode.Res);
      // this.currentStepName = StepName.PropertyAndLoan;
      // this.currentStepName = StepName.Results;
      // this.resultsView = ResultsView.Details;
    }

    this.igniteCommonDataService.affordabilityModel = this.model;

    if (resultModel) {
      if (resultModel.uiModelJson) {
        this.model = JSON.parse(resultModel.uiModelJson);
        this.model?.applicants.applicantAges?.forEach((applicant, i) => {
          applicant.applicantNumber = i + 1;
        });
        this.model?.incomeAndExpenditure?.applicants?.forEach((applicant, i) => {
          applicant.applicantNumber = i + 1;
        });
        this.affordabilityModelRequest = this.mappedModel(this.model);
        if (this.lendingTypeCode === LendingTypeCode.Res) {
          this.rioService.reset(this.model?.applicants.numberOfApplicants, this.model);
        }
        this.form.reset(this.model);
      }

      if (resultModel?.intuitiveOutcomesContextCards?.length) {
        const cards: OutcomeDetailsCard[] = [];
        resultModel.intuitiveOutcomesContextCards.forEach(card => {
          cards.push({
            answerId: card.answerId,
            context: card.context.text,
            issue: `${card.issue.text} - Intuitive Criteria`,
            questionsAndAnswers: card.questionsAndAnswers.map(cq => {
              return {
                question: cq.question.text,
                answer: cq.answer.text,
                answerType: cq.answerType,
              };
            }),
          });
        });
        this.intuitiveContextCards = cards;

        if (this.criteriaV2Enabled) {
          this.criteriaV2OutcomeService.outcome.contexts = cards;
          this.criteriaV2OutcomeService.setBasketItems(cards);
        } else {
          this.criteriaService.outcomeDetails.contexts = cards;
        }
      }
      if (resultModel?.outcomeContextCards?.length) {
        if (this.criteriaV2Enabled) {
          this.criteriaV2OutcomeService.outcome.details =
          [...resultModel.additionalCriteria, ...resultModel.intuitiveCriteria];
        } else {
          this.criteriaService.outcomeDetails.details = resultModel.additionalCriteria;
        }

        const cards: OutcomeDetailsCard[] = [];
        resultModel.outcomeContextCards.forEach(card => {
          cards.push(OutcomeHistoryDetailsCard.card(card, card.autoAdded ? ' - Added Automatically' : ''));
        });

        if (this.criteriaV2Enabled) {
          this.criteriaV2OutcomeService.outcome.contexts =
            [...cards, ...this.criteriaV2OutcomeService.outcome?.contexts ?? []];
          this.criteriaV2OutcomeService.setBasketItems(this.criteriaV2OutcomeService.outcome.contexts);
        } else {
          this.criteriaService.outcomeDetails.contexts =
            [...cards, ...this.criteriaService.outcomeDetails?.contexts ?? []];
        }

        this.updateCriteriaBasket([
          ...resultModel.outcomeContextCards,
        ]);
      }

      this.productsSearchService.matchedProductsFlat = resultModel.products
        .flatMap(p => p.products ?? [])
        .filter((product) => !!product.initialPeriod);

      this.matchedLenderProducts = resultModel.products;
      this.productsSearchService.matchedProducts = resultModel.products;

      this.igniteCommonDataService.results = {
        pollJobID: null,
        quotes: resultModel.affordabilityResults,
        affordabilitySearchId: resultModel.affordabilityResults[0]?.affordabilitySearchId ?? null,
        errorMessage: null,
        completed: true,
        lenderCount: resultModel.affordabilityResults.length,
      };
      this.lenders = resultModel.affordabilityResults.map(r => (
        {
          lenderName: r.lenderName,
          lenderLogoUrl: r.lenderLogoUrl ?? '',
        }
      ));

      this.splitOutPropertyCriteria(resultModel.intuitiveCriteria, resultModel.additionalCriteria);

      this.currentStepName = StepName.Results;
      this.resultsView = ResultsView.Details;
      this.lastSavedAffordabilitySearchId = null;
      this.miSaveOutcomeModel = resultModel.saveOutcomeModel;
    }
  }

  public resetOptions(): void {
    if (this.options.resetModel) {
      this.options.resetModel();
    }
  }

  public getOutcomeItem(outcome: OutcomeResults): ISortOption | undefined {
    return this.outcomeList.find((o) => outcome === o.value);
  }

  public getResults(lenderToolsCategory: AvailableLenderToolsCategories): IgniteResponse[] | null {
    if (!this.igniteCommonDataService.results?.quotes) {
      return null;
    }

    let resultsWithTools = this.igniteCommonDataService.results.quotes
      .map((result: IgniteResponse) => {
        const affordabilityAvailable: boolean = this.affordabilityLendersList.includes(result.lenderName);
        const productsAvailable = !!this.getLenderMatchedProducts(result)?.products?.length;
        const overallCriteriaResult: OutcomeResults | undefined = this.getOverallOutcome(
          this.calculateOverallResult(this.getIntuitiveOutcome(result.lenderName)),
          this.calculateOverallResult(this.getAdditionalOutcome(result.lenderName)));
        const criteriaAvailable: boolean = overallCriteriaResult ? overallCriteriaResult === OutcomeResults.Yes : false;
        const overallPropertyResult: OutcomeResults | undefined = this.getOverallOutcome(
          this.calculateOverallResult(this.getPropertyOutcome(result.lenderName)),
          this.calculateOverallResult(this.getPropertyIntuitiveOutcome(result.lenderName)));
        const propertyAvailable: boolean = overallPropertyResult ? overallPropertyResult === OutcomeResults.Yes : false;

        return {
          result,
          overall: this.getOverallOutcomeForLender(result),
          tools: {
            lenderName: result.lenderName,
            availableToolsCategory: this.getLenderToolsCategory(
              affordabilityAvailable, productsAvailable, criteriaAvailable, propertyAvailable, result),
          } as LenderTools,
        };
      });

    resultsWithTools = resultsWithTools.filter(({ tools }) =>
      tools.availableToolsCategory === lenderToolsCategory,
    );

    resultsWithTools = orderBy(resultsWithTools,
      [
        x => outcomeSortOrder.indexOf(
          this.getOverallSortOutcomeForLender(x.result, x.tools.availableToolsCategory)),
        x => x.result.maximumAffordableLoanAmount,
        x => {
          // Should ALWAYS order by true cost in the green/yellow categories
          // Should NEVER order by true cost in the red category
          // Should order by true cost in the white category IF the results have the products and affordability tools
          const shouldOrderByTrueCost =
            (
              x.overall &&
              [OutcomeResults.Yes, OutcomeResults.Refer].includes(x.overall) &&
              x.tools.availableToolsCategory === AvailableLenderToolsCategories.AllTools
            )
            || x.tools.availableToolsCategory === AvailableLenderToolsCategories.ProductAndAffordability;
          return (shouldOrderByTrueCost ? this.getLenderMatchedProducts(x.result)?.trueCostInitialPeriodFrom : null)
            ?? Number.MAX_VALUE;
        },
        x => x.result.lenderName,
      ],
      ['asc', 'desc', 'asc', 'asc', 'asc'],
    );
    return resultsWithTools.map(x => x.result);
  }

  public getIntuitiveOutcome(lenderName: string, intuitiveOutcomes: OutcomeResponse[] | null = null)
    : OutcomeResponse | undefined {
    if (intuitiveOutcomes) {
      return intuitiveOutcomes.find(
        (o: OutcomeResponse) => o.overallResult.lender.toUpperCase() === lenderName.toUpperCase(),
      );
    }

    if (this.criteriaV2Enabled) {
      return this.criteriaV2OutcomeService
        .getLenderCriteriaByLender(lenderName, CriteriaType.Intuitive, IntuitiveCriteriaPropertyStatus.NoProperty);
    }

    return this.intuitiveOutcomes.find(
      (o: OutcomeResponse) => o.overallResult.lender.toUpperCase() === lenderName.toUpperCase(),
    );
  }

  public getPropertyIntuitiveOutcome(lenderName: string): OutcomeResponse | undefined {
    if (this.criteriaV2Enabled) {
      return this.criteriaV2OutcomeService.
        getLenderCriteriaByLender(
          lenderName.toUpperCase(), CriteriaType.Intuitive, IntuitiveCriteriaPropertyStatus.Property);
    }

    return this.propertyIntuitiveOutcomes.find(
      (o: OutcomeResponse) => o.overallResult.lender.toUpperCase() === lenderName.toUpperCase(),
    );
  }

  public getAdditionalOutcome(lenderName: string, additionalOutcomes: OutcomeResponse[] | null = null)
    : OutcomeResponse | undefined {
    if (additionalOutcomes) {
      return additionalOutcomes.find(
        (o: OutcomeResponse) => o.overallResult.lender.toUpperCase() === lenderName.toUpperCase(),
      );
    }

    if (this.criteriaV2Enabled) {
      return this.criteriaV2OutcomeService.getLenderCriteriaByLender(
        lenderName, CriteriaType.Manual, IntuitiveCriteriaPropertyStatus.NoProperty);
    }

    return this.additionalOutcomes.find(
      (o: OutcomeResponse) => o.overallResult.lender.toUpperCase() === lenderName.toUpperCase(),
    );
  }

  public getPropertyOutcome(lenderName: string): OutcomeResponse | undefined {
    return this.propertyOutcomes.find(
      (o: OutcomeResponse) => o.overallResult.lender.toUpperCase() === lenderName.toUpperCase(),
    );
  }

  public getLenderMatchedProducts(response: IgniteResponse): MatchedLenderProducts | null {
    const matchedLenderProducts: MatchedLenderProducts | undefined = this.matchedLenderProducts.find(
      (o: MatchedLenderProducts) => o.lenderName.toUpperCase() === response.lenderName.toUpperCase(),
    );
    if (matchedLenderProducts === undefined) {
      return null;
    }

    return matchedLenderProducts;
  }

  public get isDetailsView(): boolean {
    return this.resultsView === ResultsView.Details;
  }

  public get isSummaryView(): boolean {
    return this.resultsView === ResultsView.Summary;
  }

  public setResultsView(view: ResultsView) {
    this.resultsView = view;
  }

  public get isMatchedProductsView(): boolean {
    return this.resultsView === ResultsView.MatchedProducts;
  }

  public get isViewAllProductsView(): boolean {
    return this.resultsView === ResultsView.ViewAllProducts;
  }

  public get isComparedProductsView(): boolean {
    return this.resultsView === ResultsView.ComparedProducts;
  }

  public get isInputView(): boolean {
    return this.resultsView === ResultsView.Input;
  }

  public get canSave(): boolean {
    return !this.currentViewSaved;
  }

  private get allowCriteriaPlus(): boolean {
    return this.authService.allowCriteriaPlus;
  }

  public get applicantOutcomeIds(): number[] | undefined {
    return this.model?.incomeAndExpenditure?.applicants?.flatMap(
      (applicant: IUiApplicant) => this.getApplicantOutcomeIds(applicant),
    ) ?? [];
  }

  public get applicantsAndOutcomeIds(): IApplicantOutcomeIds[] | undefined {
    return this.model?.incomeAndExpenditure?.applicants
      ?.map(
        (applicant: IUiApplicant) =>
          ({
            applicantNumber: applicant.applicantNumber,
            outcomeIds: this.getApplicantOutcomeIds(applicant),
          } as IApplicantOutcomeIds),
      )
      .filter((applicant: IApplicantOutcomeIds) => applicant.outcomeIds.length > 0);
  }

  public canShowCriteriaFields(option: IUiBaseIncome, criteriaContexts: IBaseIncomeContext[]): boolean {
    return this.allowCriteriaPlus &&
      criteriaContexts.some(
        (context) => context.category === option.category,
      ) &&
      +option.amount > 0;
  }

  public get affordabilityRequest(): AffordabilityRequest | BtlAffordabilityRequest | BdgAffordabilityRequest | null {
    return this.mappedModel(this.form.value);
  }

  public joinCriteriaResults(outcomesDetails: OutcomeResponse[]) {
    outcomesDetails?.forEach(outcome => {
      if (!this.igniteCommonDataService.results?.quotes.map(q => q.lenderName).includes(outcome.overallResult.lender)) {
        this.igniteCommonDataService.results?.quotes.push(IgniteResponse.response(outcome));
      }
    });
  }

  public getResultsStart(): Observable<[AffordabilityPollResponse]> {
    const affordabilityRequest = this.affordabilityModelRequest;
    this.miSaveOutcomeModel = null;
    const affordabilityAndUiRequest: AffordabilityAndUiRequest = {
      affordabilityRequest,
      uiModelJson: this.uiModelJson,
    };
    this.productFilterService.productsFilterContext = { repaymentMethod: affordabilityRequest?.repaymentMethod };
    this.productFilterService.setProductFilterDefinitions();
    this.loading = AffordabilityServiceLoadingViewMode.Full;
    return forkJoin([this.getAffordability(affordabilityAndUiRequest)]).pipe(
      tap(([results]) => {
        this.lenderCount = results.lenderCount;
        if (this.igniteCommonDataService.results) {
          const oldResultsLenders = this.igniteCommonDataService.results.lenders;
          this.igniteCommonDataService.results = results;
          const newResultsLendersMapName = results.lenders?.map((l) => l.lenderName);
          oldResultsLenders?.forEach(lender => {
            if (lender.lenderName && !newResultsLendersMapName?.includes(lender.lenderName)) {
              this.igniteCommonDataService.results?.lenders?.push(lender as AffordabilityLenders);
            }
          });
        } else {
          this.igniteCommonDataService.results = results;
        }
        this.lenders = results.lenders;
      }),
    );
  }

  /** Runs `getCriteria()`, `getResultsStart()` and begins polling for results.
   * Returns a promise that resolves when all operations are complete. This promise returns the outcome
   * result (from `getCriteria`). */
  public async getResultsAndPoll(scheduled: boolean, rioForm: UntypedFormGroup | undefined = undefined) {
    try {
      this.igniteResponseLoading$.next(true);
      this.affordabilityModelRequest =
        this.mappedModel(this.form.valid ? this.form.getRawValue() : this.model, rioForm);
      const criteriaPromise =
        this.criteriaV2Enabled ? firstValueFrom(this.getCriteriaV2()) : firstValueFrom(this.getCriteria());
      criteriaPromise.finally(() => (this.criteriaLoading = false));
      this.productsLoading = true;
      const getResultsPromise = firstValueFrom(this.getResultsStart())
        .then(async (res: [AffordabilityPollResponse]) => {
          const lenderProductsPromise =
            firstValueFrom(this.getMatchedLenderProducts(scheduled, res[0].affordabilitySearchId));
          lenderProductsPromise.then(() => (this.productsLoading = false));
          const timeout = new Promise((resolve) => setTimeout(resolve, 40000));
          let pollRes: AffordabilityPollResponse;
          let shouldPollTimeout = false; // becomes true when both 40 second timeout AND criteria resolved

          if (this.authService.hasRole(roles.lender)) {
            const auditModel: CreateLenderAuditLogRequest = {
              referenceId: this.igniteCommonDataService.affordabilitySearchId,
              lendingTypeId: this.lendingTypeId,
              type: LenderAuditType.Ignite,
              result: LenderAuditResult.NotCompleted,
            };
            this.lenderAuditService.addLenderAudit(auditModel).subscribe();
          }

          Promise.all([criteriaPromise, lenderProductsPromise, timeout]).then(() => (shouldPollTimeout = true));

          while (true) {
            const ticker = new Promise((resolve) => setTimeout(resolve, 3000));
            pollRes = await firstValueFrom(this.getResultsPoll(res[0].pollJobID, res[0].affordabilitySearchId));
            if (pollRes.completed || shouldPollTimeout) {
              break;
            }
            await ticker;
          }
        });

      await Promise.allSettled([criteriaPromise, getResultsPromise]);

      if (this.s365Data?.sourcingCallBack) {
        const hasAffordabilityEvents =
          this.s365Data.events?.some(x=>x.eventType === SsoCallBackEventType.Affordability);
        if (hasAffordabilityEvents) {
          const callbackRequest = this.mapSmartr365CallbackRequest();
          this.clubHubDataService.post<IgnitePlusAffordabilityCallbackRequest>(
            'SmartrFitPlusCallback',
            callbackRequest,
          ).subscribe();
        }
      }

      if (!this.authService.hasRole(roles.lender)) {
        if (!this.criteriaV2Enabled) {
          this.joinCriteriaResults((await criteriaPromise as [OutcomeResponse[], OutcomeDetails])[0]);
        } else {
          const outcomeDetails = await criteriaPromise as OutcomeDetails;
          this.joinCriteriaResults(outcomeDetails.details);
        }
      }
      this.loading = AffordabilityServiceLoadingViewMode.None;

      this.igniteCommonDataService.searchToolsInUse.affordability = true;
      this.igniteCommonDataService.searchToolsInUse.products = true;
      this.igniteCommonDataService.searchToolsInUse.criteria = true;

      if (!this.authService.hasRole(roles.staff)) {
        this.setupSightMill();
        this.document.querySelector('#siml-iframe')?.setAttribute('style', 'display:block;');
      }
      this.igniteResponseReload$.next(true);
      this.igniteResponseLoading$.next(false);
      return await criteriaPromise;
    } catch (error) {
      this.loading = AffordabilityServiceLoadingViewMode.None;
      this.criteriaV2OutcomeService.loading = false;
      this.igniteResponseLoading$.next(false);
      return null;
    }
  }

  public splitOutPropertyCriteria(
    intuitiveOutcomes: OutcomeResponse[] | null, criteriaOutcomes: OutcomeResponse[], addingProperty = false,
  ) {
    if (intuitiveOutcomes?.length) {
      // Get Intuitive outcomes
      this.intuitiveOutcomes = intuitiveOutcomes?.map(co => {
        return {
          contextResults: co.contextResults.filter(cr => !cr.isProperty),
          overallResult: co.overallResult,
          skipList: co.skipList,
        };
      }) as OutcomeResponse[];
      // Get Property Intuitive outcomes
      this.propertyIntuitiveOutcomes = intuitiveOutcomes?.map(co => {
        return {
          contextResults: co.contextResults.filter(cr => cr.isProperty),
          overallResult: co.overallResult,
          skipList: co.skipList,
        };
      }) as OutcomeResponse[];
    }

    // Get outcomes
    if (!addingProperty) {
      this.additionalOutcomes = [];
      criteriaOutcomes.forEach(co => {
        const lenderOutcome = this.additionalOutcomes.find(x => x.overallResult.lender === co.overallResult.lender);
        if (lenderOutcome) {
          lenderOutcome.contextResults = co.contextResults.filter(cr => !cr.isProperty);
          lenderOutcome.overallResult = co.overallResult;
        } else {
          this.additionalOutcomes.push({
            contextResults: co.contextResults.filter(cr => !cr.isProperty),
            overallResult: co.overallResult,
            skipList: co.skipList,
          });
        }
      });
    }
    // Get property Outcomes
    const propertyOutcomes = criteriaOutcomes.map(co => {
      return {
        contextResults: co.contextResults.filter(cr => cr.isProperty),
        overallResult: co.overallResult,
        skipList: co.skipList,
      };
    }) as OutcomeResponse[];

    // Re-add Added Automatically checks
    this.autoAddedPropertyOutcomeIds.forEach(autoAddedId => {
      propertyOutcomes.forEach(x => x.contextResults.every(y => {
        if (y.contextId === autoAddedId) {
          y.autoAdded = true;
          return false;
        }
        return true;
      }));
    });

    // Get outcome ids
    this.additionalOutcomeIds = [...new Set(this.additionalOutcomes.flatMap((x: OutcomeResponse) =>
      x.contextResults.map((c: OutcomeResponseContextResult) => c.outcomeId),
    ))];
    this.propertyOutcomeIds = [...new Set(propertyOutcomes.flatMap((x: OutcomeResponse) =>
      x.contextResults.map((c: OutcomeResponseContextResult) => c.outcomeId),
    ))];

    this.propertyOutcomes = propertyOutcomes;
  }

  private getCriteriaV2(): Observable<OutcomeDetails> {
    this.criteriaLoading = true;
    return this.criteriaV2OutcomeService.getOutcome(this.lendingTypeId,
      this.affordabilityModelRequest?.affordabilitySearchId ?? null, this.affordabilityModelRequest)
      .pipe(tap((criteriaOutcomes) => {
        this.currentViewSaved = false;
        this.criteriaSearchId = criteriaOutcomes.criteriaSearchId;
        this.criteriaService.outcomeDetails.details = criteriaOutcomes.details;
        this.splitOutPropertyCriteria(null, criteriaOutcomes.details);
        if (!this.lenders?.length) {
          this.lenders = criteriaOutcomes.details
            .map(x => ({ lenderName: x.overallResult.lender, lenderLogoUrl: x.overallResult.lenderLogoUrl }));
        }
        this.criteriaV2OutcomeService.setBasketItems(criteriaOutcomes.contexts);
      }),
      );
  }

  private getCriteria(): Observable<[OutcomeResponse[], OutcomeDetails]> {
    this.criteriaLoading = true;
    return forkJoin([
      this.affordabilityOnly
        ? this.clubHubDataService
          .post<OutcomeResponse[]>('Ignite/GetIntuitiveOutcomes', this.affordabilityModelRequest)
        : of([]),
      this.getCriteriaLenders(this.affordabilityModelRequest),
    ]).pipe(
      tap(([intuitiveOutcomes, criteriaOutcomes]: [OutcomeResponse[], OutcomeDetails]) => {
        this.currentViewSaved = false;
        this.criteriaSearchId = criteriaOutcomes.criteriaSearchId;
        this.intuitiveContextCards = intuitiveOutcomes[0].outcomeContextCards ?? [];
        this.splitOutPropertyCriteria(intuitiveOutcomes, criteriaOutcomes.details);
        this.criteriaService.outcomeDetails.details = criteriaOutcomes.details;

        let contexts = criteriaOutcomes.contexts;
        if (contexts?.length > 0 && this.intuitiveContextCards?.length > 0) {
          // remove duplicates
          contexts = contexts.filter(c => this.intuitiveContextCards.every(ic => ic.answerId !== c.answerId));
        }
        this.criteriaService.outcomeDetails.contexts =
            [...contexts, ...this.intuitiveContextCards];
      }),
    );
  }

  public get productsSearchCompleted(): EventEmitter<void> {
    return this.productsSearchService.productsSearchCompleted;
  }

  public get getFixedInitialPeriod(): number | undefined {
    return this.fixedInitialPeriod;
  }

  public set setFixedInitialPeriod(period: number | undefined) {
    this.fixedInitialPeriod = period;
  }

  private mapAffordabilityToProductRequest(
    request: AffordabilityRequest | BtlAffordabilityRequest | BdgAffordabilityRequest,
    affordabilitySearchId: string,
  ):
    ProductsResidentialModelRequest | ProductsBtlModelRequest | ProductsBdgModelRequest | undefined {
    if (request?.productType === LendingTypeCode.Res) {
      return ProductsResidentialModelRequest
        .mapAffordabilityToProductRequestResidential(request as AffordabilityRequest, affordabilitySearchId);
    } else if (request?.productType === LendingTypeCode.Btl) {
      return ProductsBtlModelRequest
        .mapAffordabilityToProductRequestBtl(request as BtlAffordabilityRequest, affordabilitySearchId);
    } else if (request.productType === LendingTypeCode.Bdg) {
      return ProductsBdgModelRequest
        .mapAffordabilityToProductRequestBdg(request as BdgAffordabilityRequest, affordabilitySearchId);
    }
  }

  private getMatchedLenderProducts(
    scheduled: boolean,
    affordabilitySearchId: string | null,
  ): Observable<MatchedLenderProducts[]> {
    const affordabilityRequest = this.affordabilityModelRequest;
    const productsSearchRequestViewModel: ProductsResidentialModelRequest
      | ProductsBtlModelRequest | ProductsBdgModelRequest | undefined =
      affordabilityRequest && affordabilitySearchId
        ? this.mapAffordabilityToProductRequest(affordabilityRequest, affordabilitySearchId)
        : undefined;

    this.productsLoading = true;

    this.productsSearchService.productsModelRequest = productsSearchRequestViewModel;
    return this.productsSearchService.getProducts(scheduled, productsSearchRequestViewModel)
      .pipe(
        tap(products => {
          if (productsSearchRequestViewModel) {
            this.matchedLenderProducts = products;
            this.productsSearchService.updateModelFromSF(productsSearchRequestViewModel);
          }
        }),
      );
  }

  public setCriteriaResults(outcomes: OutcomeDetails, criteriaSearchId: string | null) {
    this.criteriaSearchId = criteriaSearchId;
    this.splitOutPropertyCriteria(null, outcomes.details);
  }

  public setPropertyResults(
    outcomes: PropertyOutcomeDetailsDetailed,
    propertySearchId: string,
    addingProperty = false,
  ) {
    this.propertySearchId = propertySearchId;
    this.currentViewSaved = false;
    const outcomeDetails = this.criteriaV2Enabled
      ? this.criteriaV2OutcomeService.outcome
      : this.criteriaService.outcomeDetails;

    if (!outcomeDetails.details || !outcomeDetails.contexts) {
      // First time Property check
      outcomeDetails.details = [];
      outcomeDetails.contexts = [];
    } else {
      // When the client Change the property to a new address or add Property check from affordability we need to remove
      // all Added Automatically criteria so that we can add the new Property's criteria
      outcomeDetails.details.forEach(x => {
        x.contextResults = x.contextResults.filter(y => !y.isProperty);
      });
      outcomeDetails.contexts =
        outcomeDetails.contexts?.filter(x =>
          outcomeDetails.details
            .find(y => y.contextResults.find(z => z.outcomeId === x.answerId)),
        );
    }

    outcomes.details.forEach(x => {
      const lenderOutcome = outcomeDetails.details.filter(y => y.overallResult.lender === x.overallResult.lender);
      if (lenderOutcome.length) {
        const lenderDetails = lenderOutcome[0];
        lenderDetails.contextResults = lenderDetails.contextResults.concat(x.contextResults);
        // Get overall outcome Result
        const lenderOutcomeResult = lenderDetails.overallResult.outcomeResult;
        const propertySearchOutcomeResult = x.overallResult.outcomeResult;
        const overallOutcomeResult = this.getOverallOutcome(lenderOutcomeResult, propertySearchOutcomeResult);
        if (overallOutcomeResult) {
          lenderDetails.overallResult.outcomeResult = overallOutcomeResult.toString();
        }
      } else {
        outcomeDetails.details.push(x);
      }
    });

    const mappedContexts = outcomes.contexts.map(oc => {
      return {
        answerId: oc.answerId,
        context: oc.context.text,
        issue: `${oc.issue.text} - Added Automatically`,
        questionsAndAnswers: oc.questionsAndAnswers.map(ocq => {
          return {
            question: ocq.question.text,
            answer: ocq.answer.text,
          };
        }),
      };
    });

    const conceitedContexts = outcomeDetails.contexts?.concat(mappedContexts);

    outcomeDetails.contexts = [
      ...conceitedContexts,
      ...this.intuitiveContextCards,
    ];
    this.splitOutPropertyCriteria(outcomes.intuitiveDetails, outcomes.details, addingProperty);

    if (this.criteriaV2Enabled) {
      this.criteriaV2OutcomeService.updateBasketItems(outcomes.contexts as unknown as OutcomeDetailsCard[]);
    } else {
      this.updateCriteriaBasket(outcomes.contexts, true);
    }
  }

  private getResultsPoll(pollJobID: number | null, affordabilitySearchId: string | null)
    : Observable<AffordabilityPollResponse> {
    if (!pollJobID || !affordabilitySearchId) {
      return of();
    }
    return this.getAffordabilityPoll(pollJobID, affordabilitySearchId).pipe(
      tap((results: AffordabilityPollResponse) => {
        this.progress = results.quotes.length;
        const lendersNotDone = this.lenders?.filter((l) => !results.quotes.find((q) => q.lenderName === l.lenderName));
        if (lendersNotDone) {
          lendersNotDone.forEach((lender) => {
            results.quotes.push(AffordabilityPollResponse.response(lender));
          });
        }
        this.igniteCommonDataService.results = results;
      }),
    );
  }

  public retryLender(lenderName: string): Observable<IgniteResponse> {
    if (!this.igniteCommonDataService.results) {
      throw new Error('No results to iterate');
    }
    const lenderIndex = this.igniteCommonDataService.results.quotes.findIndex((q) => q.lenderName === lenderName);

    this.getCriteria();
    // this.getProducts(false);

    const affordabilityModel = this.mappedModel(this.model);
    if (affordabilityModel) {
      affordabilityModel.affordabilitySearchId = this.igniteCommonDataService.affordabilitySearchId;
      affordabilityModel.pollJobId = this.igniteCommonDataService.pollJobId;
    }

    return this.getAffordability(
      {
        affordabilityRequest: affordabilityModel,
        uiModelJson: this.uiModelJson,
      },
      lenderName,
    ).pipe(
      tap((result) => {
        if (result) {
          this.currentViewSaved = false;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.igniteCommonDataService.results!.quotes[lenderIndex] = result;
        }
      }),
    );
  }

  public async addIgniteCriteriaOutcomes(outcomeIds: (number | undefined)[], sendRequest = true) {
    this.additionalOutcomeIds = [...new Set([...this.additionalOutcomeIds, ...(outcomeIds ?? [])])];

    if (sendRequest) {
      // If results are null, user adding criteria from the 'would you like to add criteria'
      // popup after clicking get results
      // in this case, need to get results as normal.
      if (this.igniteCommonDataService.results === null) {
        const outcomes = await this.getResultsAndPoll(false);
        this.modalService.dismiss(outcomes);
      } else {
        // If results not null, adding criteria to existing fetched results so just
        // do getCriteria() without getting the entire results
        this.loading = AffordabilityServiceLoadingViewMode.CriteriaOnly;
        if (this.propertyOnly) {
          this.getCriteriaLenders(null).subscribe((criteriaOutcomes: OutcomeDetails) => {
            if (this.propertySearchRequestViewModel) {
              this.propertySearchRequestViewModel.propertySearchId = criteriaOutcomes.criteriaSearchId;
            }
            this.propertySearchId = criteriaOutcomes.criteriaSearchId;
            const endpoint = this.criteriaV2Enabled ? 'propertyCriteriaV2' : 'propertyCriteria';
            this.clubHubDataService
              .post<OutcomeResponse[]>(`${endpoint}/Intuitive`, this.propertySearchRequestViewModel)
              .subscribe(intuitiveOutcomes => {
                this.currentViewSaved = false;
                this.modalService.dismiss(criteriaOutcomes);
                this.loading = AffordabilityServiceLoadingViewMode.None;
                this.splitOutPropertyCriteria(intuitiveOutcomes, criteriaOutcomes.details);
                this.criteriaService.outcomeDetails.details = criteriaOutcomes.details;
                this.criteriaService.outcomeDetails.contexts = criteriaOutcomes.contexts;
              });
          });
        } else {
          this.getCriteria().subscribe((outcomes) => {
            this.modalService.dismiss(outcomes);
            this.loading = AffordabilityServiceLoadingViewMode.None;
          });
        }
      }
    }
  }

  public downloadSearchPdf(searchId: string | null, lenderName: string) {
    const folderName = `${searchId}/${lenderName.replace(/ /g, '').toLowerCase()}`;
    return this.clubHubDataService.getFile(`file/DownloadAsPdf?folder=${folderName}`, 'application/pdf');
  }

  public getPreviousOutcome(affordabilitySearchId: string): Observable<AffordabilityResult> {
    this.resetResults();
    this.currentStepName = StepName.Results;
    return this.clubHubDataService.get<AffordabilityResult>(`affordability/outcome/${affordabilitySearchId}`);
  }

  public addToSearchInUse(result: OutcomeHistoryResponse) {
    this.igniteCommonDataService.searchToolsInUse.affordability = !!result.affordability;
    this.igniteCommonDataService.searchToolsInUse.criteria = !!result.criteria || !!result.intuitiveOutcomes;
    this.igniteCommonDataService.searchToolsInUse.products = !!result.products;
    this.igniteCommonDataService.searchToolsInUse.property = !!result.property;
  }

  public saveMiDetails(model: MiSaveOutcomeModel): Observable<unknown> {
    return this.clubHubDataService
      .put<unknown>('outcome/setmidetails', model)
      .pipe(tap(() => (this.currentViewSaved = true)));
  }

  public getOutcomeIds(criteria: IUiCriteria | undefined): number[] {
    return (
      criteria?.questions
        .filter((x: IUiCriteriaQuestion) =>
          x.answerId && x.answers.find((a) => a.answerId === x.answerId)?.type === AnswerType.Outcome)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((x: IUiCriteriaQuestion) => x.answerId!) ?? []
    );
  }

  public noUnsuccessfulCriteria(
    result: IgniteResponse,
    intuitiveOutcomes: OutcomeResponse[] | null = null,
    additionalOutcomes: OutcomeResponse[] | null = null,
  ) {
    const intuitiveResult = this.getIntuitiveOutcome(result.lenderName, intuitiveOutcomes)?.overallResult.outcomeResult;
    const additionalResult =
      this.getAdditionalOutcome(result.lenderName, additionalOutcomes)?.overallResult.outcomeResult;
    const overallOutcome = this.getOverallOutcome(intuitiveResult, additionalResult);

    return overallOutcome !== OutcomeResults.No;
  }

  public noUnsuccessfulCriteriaWithoutAffordability(
    lenderName: string,
  ) {
    const intuitiveResult = this.getIntuitiveOutcome(lenderName)?.overallResult.outcomeResult;
    const additionalResult = this.getAdditionalOutcome(lenderName)?.overallResult.outcomeResult;
    const overallOutcome = this.getOverallOutcome(intuitiveResult, additionalResult);

    return overallOutcome !== OutcomeResults.No;
  }

  public unsuccessfulProductsWithCriteria(lender: string) {
    // Criteria v1 is deprecated. Need to remove in future
    if (!this.criteriaV2Enabled) {
      return this.criteriaService.noLenders().some(x => x.lender === lender);
    }

    return this.criteriaV2OutcomeService.getLenderOverallResultByLender(lender) === OutcomeResults.No;
  }

  public getOverallOutcome(...results: (OutcomeResults | string | undefined)[]) {
    // criteria v2 rules
    if (this.criteriaV2Enabled) {
      return this.criteriaV2OutcomeService.getOverallOutcome(...results);
    }
    // criteria v1 rules - can be removed when criteria v2 goes live
    if (results.every(r => r === OutcomeResults.Unset || r === undefined)) {
      return undefined;
    }

    if (results.some(r => r === OutcomeResults.No)) {
      return OutcomeResults.No;
    }

    if (results.some(r => r === OutcomeResults.Refer)) {
      return OutcomeResults.Refer;
    }

    if (results.some(r => r === OutcomeResults.Yes)) {
      return OutcomeResults.Yes;
    }

    return undefined;
  }

  public getCriteriaFilterResult(result: IgniteResponse) {
    return this.getOverallOutcome(
      this.calculateOverallResult(this.getIntuitiveOutcome(result.lenderName)),
      this.calculateOverallResult(this.getAdditionalOutcome(result.lenderName)),
    );
  }

  public getPropertyFilterResult(result: IgniteResponse) {
    return this.getOverallOutcome(
      this.calculateOverallResult(this.getPropertyOutcome(result.lenderName)),
      this.calculateOverallResult(this.getPropertyIntuitiveOutcome(result.lenderName)),
    );
  }

  public initialModel(): IUiAffordabilityModel {
    return IUiAffordabilityModel.initialModel(this.initialApplicantAge, this.initialApplicant);
  }

  private getAffordability(model: AffordabilityAndUiRequest): Observable<AffordabilityPollResponse>;
  private getAffordability(model: AffordabilityAndUiRequest, lenderName?: string): Observable<IgniteResponse>;
  private getAffordability(
    model: AffordabilityAndUiRequest,
    lenderName?: string,
  ): Observable<IgniteResponse> | Observable<AffordabilityPollResponse> {
    if (lenderName) {
      return this.mockAffordability
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ? of(mockAffordabilityResponse).pipe(map((lender) => lender.quotes.find((l) => l.lenderName === lenderName)!))
        : this.clubHubDataService.post<IgniteResponse>(`Affordability/${lenderName}`, model);
    }

    return this.mockAffordability
      ? of(mockAffordabilityResponse)
      : this.clubHubDataService.post<AffordabilityPollResponse>('Affordability', model);
  }

  private getAffordabilityPoll(
    pollJobID: number, affordabilitySearchId: string,
  ): Observable<AffordabilityPollResponse> {
    return this.mockAffordability
      ? of(mockAffordabilityResponse)
      : this.clubHubDataService.get<AffordabilityPollResponse>(
        `Affordability/Poll/${pollJobID}/${affordabilitySearchId}?productType=${this.lendingTypeCode}`,
      );
  }

  private getCriteriaLenders(model: AffordabilityRequest | BtlAffordabilityRequest |
    BdgAffordabilityRequest | null): Observable<OutcomeDetails> {
    const allOutcomeIds = [
      ...model?.outcomeIds ?? [],
      ...(this.applicantOutcomeIds ? this.applicantOutcomeIds : []),
      ...this.basketService.answerIds,
    ];
    const distinctIds = [...new Set(allOutcomeIds)];
    return allOutcomeIds.length > 0
      ? this.clubHubDataService.post<OutcomeDetails>('Ignite/GetLenders', {
        outcomeIds: distinctIds,
        appName: this.propertyOnly ? appName.propertyCriteria : appName.criteria,
        affordabilitySearchId: this.propertyOnly
          ? null
          : this.criteriaOnly
            ? (this.igniteCommonDataService.results as CriteriaResponse).criteriaSearchId
            : model?.affordabilitySearchId,
        autoAddedOutcomeIds: this.autoAddedPropertyOutcomeIds,
      })
      : of({
        dateTime: '',
        contexts: [],
        details: [],
        lendingType: '',
        autoAddedOutcomeIds: [],
        criteriaSearchId: null,
      });
  }

  private getAllOutcomeIds(): number[] {
    if (!this.model) {
      return [];
    }
    const { propertyAndLoan } = this.model;

    const outcomeIds: IUiCriteria[] = [];

    if (propertyAndLoan.capitalRaisingCriteria &&
      propertyAndLoan.capitalRaising?.value && propertyAndLoan.capitalRaising?.showCriteria) {
      outcomeIds.push(propertyAndLoan.capitalRaisingCriteria);
    }
    if (
      propertyAndLoan.repaymentMethodInterestOnlyCriteria &&
      propertyAndLoan.repaymentMethod?.value === RepaymentMethod.InterestOnly &&
      propertyAndLoan.repaymentMethod?.showInterestOnlyCriteria
    ) {
      outcomeIds.push(propertyAndLoan.repaymentMethodInterestOnlyCriteria);
    }
    if (
      propertyAndLoan.repaymentMethodInterestOnlyPartAndPartCriteria &&
      propertyAndLoan.repaymentMethod?.value === RepaymentMethod.InterestOnlyPartAndPart &&
      propertyAndLoan.repaymentMethod?.showInterestOnlyPartAndPartCriteria
    ) {
      outcomeIds.push(propertyAndLoan.repaymentMethodInterestOnlyPartAndPartCriteria);
    }

    return outcomeIds.flatMap((criteria: IUiCriteria) => this.getOutcomeIds(criteria));
  }

  public mappedModel(
    uiModel: IUiAffordabilityModel | undefined,
    rioForm: UntypedFormGroup | null = null,
    ignoreServiceUpdated: boolean = false,
  ): AffordabilityRequest | BtlAffordabilityRequest | BdgAffordabilityRequest | null {
    if (!isEmpty(uiModel)) {
      switch (this.lendingTypeCode.toUpperCase()) {
        case LendingTypeCode.Res:
          if (!ignoreServiceUpdated) {
            this.setFixedInitialPeriod = uiModel.propertyAndLoan.productLength;
          }
          return AffordabilityRequest.mappedModelResi(
            uiModel,
            rioForm,
            newGuid(),
            this.getAllOutcomeIds(),
            this.mapCurrentMortgageLender(uiModel.propertyAndLoan),
            this.helperService.getDateOrNull,
            this.mapMainIncome.bind(this),
          );
        case LendingTypeCode.Btl:
          if (!ignoreServiceUpdated) {
            this.setFixedInitialPeriod = uiModel.propertyAndLoan.productLength;
          }
          return BtlAffordabilityRequest.mappedModelBtl(
            uiModel,
            newGuid(),
            this.getAllOutcomeIds(),
            this.mapCurrentMortgageLender(uiModel.propertyAndLoan),
            this.helperService.getDateOrNull,
            this.mapMainIncome.bind(this),
          );
        case LendingTypeCode.Bdg:
          return {
            regulated: true,
          } as BdgAffordabilityRequest;
        default:
          return null;
      }
    }
    return null;
  }

  private mapCurrentMortgageLender(propertyAndLoanStep: IPropertyAndLoan) {
    return this.helperService.getCurrentMortgageLender(
      propertyAndLoanStep.currentMortgageLender, propertyAndLoanStep.mortgageLender);
  }

  private get uiModelJson(): string {
    const uiModelObj = this.form.getRawValue();
    if (this.lendingTypeCode === LendingTypeCode.Res) {
      uiModelObj.rioApplicants = this.rioService.rioForm?.getRawValue()?.applicants;
    }
    return JSON.stringify(uiModelObj);
  }

  private get initialApplicantAge(): IUiApplicantAge {
    return IUiApplicantAge.initialApplicantAge();
  }

  private get initialApplicant(): IUiApplicant {
    return IUiApplicant.initialApplicant();
  }

  private get initialPropertyBtl(): IPropertyBtl {
    return IPropertyBtl.initialPropertyBtl();
  }

  private get initialPropertyResi(): IUiOtherMortgage {
    return IUiOtherMortgage.initialPropertyResi();
  }

  private mapMainIncome(mainIncome: IUiMainIncome, affordabilityType: LendingTypeCode): MainIncome {
    if (mainIncome.grossSalaryForPreviousPeriod) {
      this.formStateService.hideGrossSalaryPreviousFields = false;
    }
    if (mainIncome.grossDividendsForPreviousPeriod) {
      this.formStateService.hideGrossDividendsPreviousFields = false;
    }
    if (mainIncome.netProfitBeforeDividendsForPreviousPeriod) {
      this.formStateService.hideNetProfitBeforeDividendsPreviousFields = false;
    }
    return MainIncome.mapMainIncome(mainIncome, affordabilityType);
  }

  private getApplicantOutcomeIds(applicant: IUiApplicant): number[] {
    const workAdditionalIncomesOutcomeIds =
      applicant.workAdditionalIncome
        ?.filter(
          (x: UiAdditionalIncome) => this.canShowCriteriaFields(x.option, workRelatedIncomeCriteria) &&
            x.option.showCriteria && x.criteria,
        )
        .flatMap((x: UiAdditionalIncome) => this.getOutcomeIds(x.criteria)) ?? [];

    const benefitAdditionalIncomesOutcomeIds =
      applicant.benefitAdditionalIncome
        ?.filter(
          (x: UiAdditionalIncome) => this.canShowCriteriaFields(x.option, benefitIncomeCriteria)
            && x.option.showCriteria && x.criteria,
        )
        .flatMap((x: UiAdditionalIncome) => this.getOutcomeIds(x.criteria)) ?? [];

    const otherAdditionalIncomeOutcomeIds =
      applicant.otherAdditionalIncome
        ?.filter(
          (x: UiAdditionalIncome) => this.canShowCriteriaFields(x.option, otherIncomeCriteria)
            && x.option.showCriteria && x.criteria,
        )
        .flatMap((x: UiAdditionalIncome) => this.getOutcomeIds(x.criteria)) ?? [];

    return [
      ...workAdditionalIncomesOutcomeIds,
      ...benefitAdditionalIncomesOutcomeIds,
      ...otherAdditionalIncomeOutcomeIds,
    ];
  }

  private setupSightMill() {
    setTimeout(() => {
      this.authService.getUser().subscribe((user) => {
        if (user) {
          const script = this.document.createElement('script');
          const src = 'assets/js/sightmill-ignite.js';
          script.type = 'text/javascript';
          script.src = src;
          script.dataset.email = user.email;
          script.dataset.firmName = user.firmName;
          if (!this.utilService.scriptExists(src)) {
            this.document.getElementsByTagName('head')[0].appendChild(script);
          }
        }
      });
    }, 2000);
  }

  public getLoanAmount() {
    return this.affordabilityModelRequest?.loanAmount;
  }

  public getMortgageTerm(): string {
    const mortgageTerm: number | undefined = this.affordabilityModelRequest?.mortgageTermYears;
    if (mortgageTerm) {
      const length: string = (mortgageTerm > 1) ? 'years' : 'year';
      return `${ mortgageTerm } ${ length }`;
    }

    return '';
  }

  public getRepaymentMethod(): string {
    return repaymentMethodToString(this.affordabilityModelRequest?.repaymentMethod);
  }

  public async gotoOutcomes() {
    this.modalService.close();
    this.currentStepName = StepName.Results;
    this.igniteCommonDataService.searchToolsInUse.criteria = true;
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 200);
    this.analyticsService.log('Ignite Results', 'L&G');
    if (this.criteriaV2Enabled) {
      if (this.igniteCommonDataService.results === null) {
        const outcomes = await this.getResultsAndPoll(false);
        this.modalService.dismiss(outcomes);
      }
      this.getCriteriaV2().subscribe({
        next:() => {
          this.loading = AffordabilityServiceLoadingViewMode.None;
          this.setProductFiltersFromCriteria();
        },
        error:() => {
          this.loading = AffordabilityServiceLoadingViewMode.None;
          this.criteriaV2OutcomeService.loading = false;
        },
      });
    } else {
      this.addIgniteCriteriaOutcomes(this.basketService.answerIds);
      this.setProductFiltersFromCriteria();
    }
  }

  public set auditResultsStatusChange(status: AuditResultsStatus) {
    this.auditResultsStatus = status;
  }

  public get auditResultsCurrentStatus() {
    return this.auditResultsStatus;
  }

  public get willBtlLendersLend() {
    const willLend = !this.model || !this.igniteCommonDataService.results
      ? []
      : this.igniteCommonDataService.results.quotes.filter(
        quote => (quote.maximumLoanAmount ?? -1) >= (this.model ? this.model.propertyAndLoan.loanAmount : 0),
      );
    return willLend.length
      ? `Good news! ${willLend.length} lender${willLend.length > 1 ? 's' : ''} may look to lend the requested amount
          based on the ICR calculations.`
      : 'Rental Shortfall! We don\'t have any lenders that will lend for the loan amount on the information ' +
      'you have entered.';
  }

  public prePopulateFromS365Data(model: IUiAffordabilityModel | undefined):void {
    this.form = new UntypedFormGroup({});
    this.model = model;
    this.igniteCommonDataService.affordabilityModel = this.model ?? null;
    this.affordabilityModelRequest = this.mappedModel(this.model);
    if (this.lendingTypeCode === LendingTypeCode.Res) {
      this.rioService.reset(this.model?.applicants.numberOfApplicants, this.model);
    }
    this.form.reset(this.model);
  }

  public setProductFiltersFromCriteria() {
    const contexts = this.criteriaV2Enabled
      ? this.criteriaV2OutcomeService.basketItems
      : this.basketService.basketItems.flatMap((item: IBasketItem) => item?.contexts);
    contexts.forEach(context => {
      const contextName = this.criteriaV2Enabled
        ? context.criteriaContextName
        : context?.context?.text;
      const filterName = this.productFilterService.mapCriteriaToFilters(contextName);
      if (filterName) {
        this.productFilterService.setFilter('Tailored Products', filterName, true);
      }
    });
  }

  public calculateOverallResult(outcome: OutcomeResponse | undefined): OutcomeResults | undefined {
    const outcomes = outcome?.contextResults.map(o => o.outcomeResult);
    if (!outcomes?.length) {
      return this.criteriaV2Enabled ? undefined : OutcomeResults.Unset;
    }
    if (!outcomes || outcomes.includes(OutcomeResults.Unset)) {
      return OutcomeResults.Unset;
    } else if (outcomes.includes(OutcomeResults.No)) {
      return OutcomeResults.No;
    } else if (outcomes.includes(OutcomeResults.Refer)) {
      return OutcomeResults.Refer;
    }

    return OutcomeResults.Yes;
  }

  private getLenderToolsCategory(
    affordabilityAvailable: boolean,
    productsAvailable: boolean,
    criteriaAvailable: boolean,
    propertyAvailable: boolean,
    result: IgniteResponse,
  ): AvailableLenderToolsCategories {
    const affordabilityMet = this.getAffordabilityOutcome(result) === OutcomeResults.Yes;
    const isAffordabilityMetAndChecked = affordabilityMet && affordabilityAvailable;

    switch (true) {
      case !!(result.httpError ?? result.error?.message):
        if (criteriaAvailable || productsAvailable || propertyAvailable) {
          return AvailableLenderToolsCategories.AffordabilityErrorAndProductOrCriteriaOrProperty;
        }
        return AvailableLenderToolsCategories.None;
      case isAffordabilityMetAndChecked && productsAvailable && criteriaAvailable:
        return AvailableLenderToolsCategories.AllTools;
      case criteriaAvailable && propertyAvailable && productsAvailable:
        return AvailableLenderToolsCategories.ProductAndCriteriaAndProperty;
      case isAffordabilityMetAndChecked && (criteriaAvailable || productsAvailable || propertyAvailable):
        return AvailableLenderToolsCategories.AffordabilityMetAndProductOrCriteriaOrProperty;
      case affordabilityAvailable && (criteriaAvailable || productsAvailable || propertyAvailable):
        return AvailableLenderToolsCategories.AffordabilityAndProductOrCriteriaOrProperty;
      case isAffordabilityMetAndChecked:
        return AvailableLenderToolsCategories.AffordabilityMet;
      case criteriaAvailable || productsAvailable || propertyAvailable:
        return AvailableLenderToolsCategories.ProductOrCriteriaOrProperty;
      case !affordabilityAvailable && !criteriaAvailable && !productsAvailable && propertyAvailable:
        return AvailableLenderToolsCategories.PropertyOnly;
      case affordabilityAvailable && productsAvailable:
        return AvailableLenderToolsCategories.ProductAndAffordability;
      case criteriaAvailable && productsAvailable:
        return AvailableLenderToolsCategories.ProductAndCriteria;
      case propertyAvailable && productsAvailable:
        return AvailableLenderToolsCategories.ProductAndProperty;
      case affordabilityAvailable && criteriaAvailable && propertyAvailable:
        return AvailableLenderToolsCategories.AffordabilityAndCriteriaAndProperty;
      case criteriaAvailable && affordabilityAvailable:
        return AvailableLenderToolsCategories.AffordabilityAndCriteria;
      case propertyAvailable && affordabilityAvailable:
        return AvailableLenderToolsCategories.AffordabilityAndProperty;
      case criteriaAvailable && propertyAvailable:
        return AvailableLenderToolsCategories.CriteriaAndProperty;
      case criteriaAvailable || affordabilityAvailable:
        return AvailableLenderToolsCategories.AffordabilityOrCriteria;
      case !affordabilityAvailable:
        return AvailableLenderToolsCategories.AffordabilityNotChecked;
      default:
        return AvailableLenderToolsCategories.None;
    }
  }

  private getOverallOutcomeForLender(response: IgniteResponse): OutcomeResults | undefined {
    if (this.propertyOnly || this.criteriaOnly) {
      return this.getOverallOutcome(
        this.calculateOverallResult(this.getPropertyOutcome(response.lenderName)),
        this.calculateOverallResult(this.getPropertyIntuitiveOutcome(response.lenderName)),
        this.calculateOverallResult(this.getIntuitiveOutcome(response.lenderName)),
        this.calculateOverallResult(this.getAdditionalOutcome(response.lenderName)));
    }
    return this.getOverallOutcome(
      this.calculateOverallResult(this.getIntuitiveOutcome(response.lenderName)),
      this.calculateOverallResult(this.getAdditionalOutcome(response.lenderName)),
      this.calculateOverallResult(this.getPropertyOutcome(response.lenderName)),
      this.calculateOverallResult(this.getPropertyIntuitiveOutcome(response.lenderName)),
      this.affordabilityModelRequest?.loanAmount &&
        ((response.maximumValue ?? -1) >= this.affordabilityModelRequest?.loanAmount)
        ? OutcomeResults.Yes
        : OutcomeResults.No,
      !!this.getLenderMatchedProducts(response)?.products && this.noUnsuccessfulCriteria(response) ?
        OutcomeResults.Yes : OutcomeResults.No);
  }

  private getAffordabilityOutcome(response: IgniteResponse): OutcomeResults {
    return response.maximumValue &&
      this.affordabilityModelRequest?.loanAmount &&
      response.maximumValue >= this.affordabilityModelRequest?.loanAmount
      ? OutcomeResults.Yes
      : OutcomeResults.No;
  }

  // Similar to the above but takes into account the tools available for this lender, and uses that to determine the
  // overall outcome rather than whether the search results are criteria only/property only/etc
  private getOverallSortOutcomeForLender(response: IgniteResponse, lenderTools: AvailableLenderToolsCategories) {
    const intuitiveOutcome = this.calculateOverallResult(this.getIntuitiveOutcome(response.lenderName));
    const additionalOutcome = this.calculateOverallResult(this.getAdditionalOutcome(response.lenderName));
    const propertyOutcome = this.calculateOverallResult(this.getPropertyOutcome(response.lenderName));
    const propertyIntuitiveOutcome = this.calculateOverallResult(this.getPropertyIntuitiveOutcome(response.lenderName));
    const affordabilityMet = this.getAffordabilityOutcome(response);
    const productsMatched =
      (this.getLenderMatchedProducts(response)?.products?.length ?? 0) > 0 && this.noUnsuccessfulCriteria(response)
        ? OutcomeResults.Yes
        : OutcomeResults.No;

    switch (lenderTools) {
      case AvailableLenderToolsCategories.AllTools:
        return this.getOverallOutcome(affordabilityMet, intuitiveOutcome, additionalOutcome, propertyOutcome,
          propertyIntuitiveOutcome, productsMatched);
      case AvailableLenderToolsCategories.AffordabilityErrorAndProductOrCriteriaOrProperty:
        return this.getOverallOutcome(intuitiveOutcome, additionalOutcome, propertyOutcome,
          propertyIntuitiveOutcome, productsMatched);
      case AvailableLenderToolsCategories.ProductAndAffordability:
        return this.getOverallOutcome(productsMatched, affordabilityMet);
      case AvailableLenderToolsCategories.ProductAndCriteriaAndProperty:
        return this.getOverallOutcome(productsMatched, intuitiveOutcome, additionalOutcome, propertyOutcome,
          propertyIntuitiveOutcome);
      case AvailableLenderToolsCategories.ProductOrCriteriaOrProperty:
        return this.getOverallOutcome(productsMatched, intuitiveOutcome, additionalOutcome, propertyOutcome,
          propertyIntuitiveOutcome);
      case AvailableLenderToolsCategories.ProductAndCriteria:
        return this.getOverallOutcome(productsMatched, intuitiveOutcome, additionalOutcome);
      case AvailableLenderToolsCategories.ProductAndProperty:
        return this.getOverallOutcome(productsMatched, propertyOutcome, propertyIntuitiveOutcome);
      case AvailableLenderToolsCategories.PropertyOnly:
        return this.getOverallOutcome(propertyOutcome, propertyIntuitiveOutcome);
      case AvailableLenderToolsCategories.AffordabilityAndCriteriaAndProperty:
        return this.getOverallOutcome(affordabilityMet, intuitiveOutcome, additionalOutcome, propertyOutcome,
          propertyIntuitiveOutcome);
      case AvailableLenderToolsCategories.AffordabilityAndCriteria:
        return this.getOverallOutcome(affordabilityMet, intuitiveOutcome, additionalOutcome);
      case AvailableLenderToolsCategories.AffordabilityAndProperty:
        return this.getOverallOutcome(affordabilityMet, propertyOutcome, propertyIntuitiveOutcome);
      case AvailableLenderToolsCategories.CriteriaAndProperty:
        return this.getOverallOutcome(intuitiveOutcome, additionalOutcome, propertyOutcome, propertyIntuitiveOutcome);
      case AvailableLenderToolsCategories.AffordabilityNotChecked:
      case AvailableLenderToolsCategories.AffordabilityMet:
      case AvailableLenderToolsCategories.AffordabilityOrCriteria:
        // Special handling. Should return Yes if EITHER criteria or property are yes, ignoring the other outcomes
        const criteriaOverall = this.getOverallOutcome(intuitiveOutcome, additionalOutcome);
        if (affordabilityMet === OutcomeResults.Yes || criteriaOverall === OutcomeResults.Yes) {
          return OutcomeResults.Yes;
        } else if (criteriaOverall === OutcomeResults.Refer) {
          return OutcomeResults.Refer;
        } else if ((response.maximumValue && affordabilityMet === OutcomeResults.No)
          || criteriaOverall === OutcomeResults.No) {
          return OutcomeResults.No;
        }
        return undefined;
      default:
        return OutcomeResults.Unset;
    }
  }

  public checkCustomLenderErrorMessage(result: IgniteResponse): string {
    if (this.igniteCommonDataService.checkEarlShilton(result)) {
      return 'Lender has no products available for the current initial product period you have selected.';
    } else if (this.igniteCommonDataService.checkHinckleyAndRugby(result)) {
      return 'Refer to Lender for 3 and 4 applicants.';
    }
    return '';
  }

  public calculateOverallVerified(outcome: OutcomeResponse) {
    const verifications = outcome?.contextResults.map(o => o.verified);
    if (!verifications?.length) {
      return false;
    }
    if (verifications.includes(false)) {
      return false;
    }

    return true;
  }

  public s365DataReset() : Observable<any> {
    if (this.s365Data) {
      return this.clubHubDataService.delete('affordability/clearPrePopFormValues')
        .pipe(tap(() =>  {
          this.s365Data = null;
          this.pageNavMessageService.removeFromMessages(NavMessageKeys.CaseLink);
        }));
    }
    return this.clubHubDataService
      .get<CaseData>('affordability/retrievePrePopFormValues')
      .pipe(tap((data) => {
        if (data) {
          this.s365Data = data;
          this.modalService.open({
            title: 'Info',
            component: CaseLinkModalComponent,
            size: 'md',
            hideHeader: true,
            sticky: true,
          }).then(() => null, () => null);
        }
      }));
  }

  public get hasBetaFeaturesRole():boolean {
    return this.authService.hasRole(roles.betaFeaturesClubHub);
  }

  public additionalMaxAffordabilityLoanMessage(result: IgniteResponse) : MaxAffordabilityLoanMessage | undefined {
    if (result.lenderName === 'BM Solutions' && this.checkBMSolutions()) {
      return {
        shortMsg: 'Check lender portfolio criteria',
        hoverMsg: 'The applicant may not fit the portfolio criteria, check lender website for more information',
      };
    }
  }

  public checkBMSolutions() : boolean {
    const btlRequest = this.affordabilityRequest as BtlAffordabilityRequest;

    if (!btlRequest.portfolioLandlord) {
      return false;
    }

    const rentRequired = btlRequest.totalGrossMonthlyRent ?? 0;
    const loanAmount = btlRequest.totalPortfolioMortgageBalance ?? 0;
    const stressedRCR = rentRequired / ((loanAmount * 0.055) / 12);

    return stressedRCR < 1.45;
  }

  public get isLoggedIn() {
    return this.authService.isLoggedIn;
  }

  public get isProductsOnly(): boolean {
    return this.igniteCommonDataService.searchToolsInUse.products &&
      !this.igniteCommonDataService.searchToolsInUse.affordability;
  }

  public get preStandardSearchChanges(): boolean {
    if (this.isProductsOnly) {
      return !!this.productsSearchService.model &&
        !this.productsSearchService.model.mortgageRequirements.productTypeExtended;
    }

    // btl affordability doesn't have a productTypeExtended field
    return !!this.model &&
      !this.model.propertyAndLoan.productTypeExtended &&
      this.lendingTypeCode.toUpperCase() !== LendingTypeCode.Btl;
  }

  public get showReRunCaseModal() {
    if (this.isProductsOnly && this.lendingTypeCode.toUpperCase() === LendingTypeCode.Bdg) {
      return false;
    }
    const criteriaV2Disabled = this.historicalCriteriaVersion === 1;

    return criteriaV2Disabled || this.preStandardSearchChanges;
  }

  private mapSmartr365CallbackRequest() : IgnitePlusAffordabilityCallbackRequest {
    return {
      data: {
        affordabilityRequest: this.affordabilityModelRequest,
        results: this.igniteCommonDataService.results?.quotes?.map(quote => {
          return {
            lenderName: quote.lenderName,
            maximumValue: quote.maximumAffordableLoanAmount,
            error: quote.error?.message ?? quote.httpErrorMessage,
          } as IgnitePlusAffordabilityResult;
        }),
      } as AffordabilityCallbackRequestData,
      events: this.s365Data?.events?.filter(x=>x.eventType === SsoCallBackEventType.Affordability),
      clientDetails: this.s365Data?.clientDetails,
      organisationId: this.s365Data?.organisationId,
      reference: this.s365Data?.reference,
    } as IgnitePlusAffordabilityCallbackRequest;
  }
}
