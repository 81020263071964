import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventBusService {
  private maximizeProductsResultsChanged = new Subject<boolean>();
  public maximizeProductsResultsChanged$ = this.maximizeProductsResultsChanged.asObservable();

  public maximizeProductsResults(isMaximized: boolean) {
    this.maximizeProductsResultsChanged.next(isMaximized);
  }
}
