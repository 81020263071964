import { Injectable } from '@angular/core';
import { Observable, map, of, tap } from 'rxjs';

import { ClubHubDataService } from '@msslib/services/clubhub-data.service';
import { NewFeatures } from '@msslib/models/new-features';
import { AuthorizeService } from '@msslib/services/authorize.service';

@Injectable({
  providedIn: 'root',
})
export class NewFeatureService {
  private featuresCache: Map<NewFeatures, boolean> = new Map<NewFeatures, boolean>();

  public constructor(private clubHubDataService: ClubHubDataService, private authService: AuthorizeService) {}

  public getUnseenFeatures(features: NewFeatures[]): Observable<Map<string, { seen: boolean }>> {
    if (!this.authService.isLoggedIn) {
      return of(new Map());
    }

    const notCachedFeatures = features.filter((f) => !this.featuresCache.has(f));

    return this.clubHubDataService
      .post<{ [key: string]: { seen: boolean } }>('NewFeature/UnseenFeatures', notCachedFeatures)
      .pipe(
        map((response) => new Map<string, { seen: boolean }>(Object.entries(response))),
      );
  }

  public checkIfNewFeatureHasBeenSeen(feature: NewFeatures): Observable<{ seen: boolean }> {
    if (this.authService.isLoggedIn) {
      if (this.featuresCache.has(feature)) {
        return of({
          seen: !!this.featuresCache.get(feature),
        });
      }

      return this.clubHubDataService.get<{ seen: boolean }>(`NewFeature/${feature}`);
    }

    return of({ seen: true });
  }

  public setUserHasSeenNewFeature(feature: NewFeatures, cacheFeature = false): Observable<void> {
    return this.setUserHasSeenNewFeatures([feature], cacheFeature);
  }

  public setUserHasSeenNewFeatures(features: NewFeatures[], cacheFeature = false): Observable<void> {
    if (!this.authService.isLoggedIn) {
      return of();
    }

    return this.clubHubDataService.post<never>('NewFeature/UserClosedNewFeatures', features).pipe(
      tap(() => {
        if (cacheFeature) {
          features.forEach((f) => {
            this.featuresCache.set(f, true);
          });
        }
      }),
    );
  }
}
