export enum NewFeatures {
  SFInSC = 'Linking up SF to SC',
  BtlIsLive = 'BtlIsLive',
  Rebranding = 'Rebranding',
  PanelRestrictionMessage = 'PanelRestrictionMessage',
  UserCustomProductSettings = 'UserCustomProductSettings',
  UserProductsUploadWarning = 'UserProductsUploadWarning',
  CriteriaAffordabilityFilterProducts = 'CriteriaAffordabilityFilterProducts',
  ColumnsConfiguration = 'ColumnsConfiguration',
}
